import { Flex } from "@chakra-ui/react";
import { InputField } from "components/molecules";
import { FormikValues, useFormikContext } from "formik";

export const EditAge = () => {
  const { values }: FormikValues = useFormikContext();

  return (
    <Flex>
      <InputField
        name="age"
        value={values.age}
        isRequired={true}
        label="Age"
        type="number"
        placeholder="24"
      />
    </Flex>
  );
};
