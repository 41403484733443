import { Flex, VStack, Text, Hide } from '@chakra-ui/react';
import { ReviewButton } from 'components/molecules';
import ThumbsUp from 'assets/images/ThumbsUp.svg';
import ThumbsDown from 'assets/images/ThumbsDown.svg';
import Heart from 'assets/images/Heart.svg';
import { ShareIcon } from 'lucide-react';

export const ReviewButtons = ({ onClick }: { onClick: (arg: "accept" | "reject" | "shortlist" | "share") => void }) => {
  return (
    <>
    <Hide above="md">
      <Flex
        position="fixed"
        cursor="pointer"
        _hover={{ backgroundColor: "#E2E8F0" }}
        bottom={["120px"]}
        boxShadow={"lg"}
        zIndex="1000"
        right={["10px"]}
        padding="12px"
        backgroundColor={"#F7F7F7"}
        borderRadius="50%"
        width="48px"
        height="48px"
        onClick={() => onClick('share')}
      >
        <ShareIcon size={"20px"} />
      </Flex>    
    </Hide>
    
    <Flex
      position="sticky"
      bottom={["0"]}
      zIndex="1000"
      left="0"
      width="100%"
      justifyContent="center"
      alignItems="center"
      backgroundColor="#F7F7F7"
      padding="12px"
    >
      {/* Reject Button with Text */}
      <VStack align="center" marginX="8px">
        <ReviewButton
          onClick={() => onClick('reject')}
          icon={ThumbsDown}
          bgColor="black"
          padding="3"
        />
        <Text fontSize="sm" color="gray.600">Reject</Text>
      </VStack>

      {/* Shortlist Button with Text */}
      <VStack align="center" marginX="8px">
        <ReviewButton
          icon={Heart}
          bgColor="green.500"
          padding="3"
          onClick={() => onClick('shortlist')}
        />
        <Text fontSize="sm" color="gray.600">Shortlist</Text>
      </VStack>

      {/* Apply Button with Text */}
      <VStack align="center" marginX="8px">
        <ReviewButton
          icon={ThumbsUp}
          bgColor="rgba(252, 64, 107, 1)"
          padding="3"
          onClick={() => onClick('accept')}
        />
        <Text fontSize="sm" color="gray.600">Apply</Text>
      </VStack>
    </Flex>
    </>
  );
};
