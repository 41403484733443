import { Avatar, Divider, Flex, Grid, GridItem, Input, InputGroup, InputRightElement, Show, theme } from "@chakra-ui/react";
import { Body1, Body3, Caption2 } from "components/molecules";
import defaultPfp from 'assets/images/photoHighlights-2c716dbb164304be.png'
import { useEffect, useRef, useState } from "react";
import { ChevronLeft, Ellipsis, SendIcon } from "lucide-react";
import { useGetMessages, useGetChats } from "lib";
import { Spinner } from "components/atoms";
import { useSeenMessage, useSendMessage, useUser } from "lib";
import { useQueryClient } from "@tanstack/react-query";
import { socket } from "app/socket";
import { ChatMessages } from "types";
import { Link, useParams } from "react-router-dom";

const ChatPreview = ({chats, selected}: {chats: Array<any>, selected: string|undefined}) => {
  return(
  <>
    {chats.map(({profilepic, name, msgs, _id}, i) => (
      <Link to={"/app/chat/"+_id}>
        <Flex 
        key={i} 
        cursor={'pointer'} 
        flexDir={'row'} 
        p={[1, 4]} borderRadius={'8px'}
        bgColor={selected === _id ? 'green.500' : 'none'} 
        // onClick={() => setSelected(i)}
        _hover={selected === _id ? {} : {bgColor: 'rgba(255, 255, 255, 0.7)'}}
        >
          <Avatar src={`${process.env.REACT_APP_BASE_URL}/${profilepic}`} rounded={'50%'} />
          <Flex ml={[1, 2]} flexDir={'column'} maxWidth={'100%'}>
            <Body1 color={selected === _id  ? 'white' : 'black.900'}>{name}</Body1>
            <Body3 color={selected === _id ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'} noOfLines={1}>{msgs?.length > 0 ? msgs[msgs.length - 1].msg : 'You both matched, start a conversation'}</Body3>
          </Flex>
        </Flex>
      </Link>
    ))}
  </>
)}

const ChatHeader = ({chat}: any) => {
  return(
    <Grid 
    templateRows='repeat(1, 1fr)'
    templateColumns={`repeat(2, 1fr)`}
    width={'100%'}>
      <GridItem display={'flex'} alignItems={'center'}>
        <Link to="/app/chat">
          <Flex style={{backgroundColor: theme.colors.black[800]}}>
            <ChevronLeft />
          </Flex>
        </Link>
        <Avatar src={`${process.env.REACT_APP_BASE_URL}/${chat.pfp}`} size={'sm'} mr={[1, 2]} />
        <Body1>{chat.name}</Body1>
      </GridItem>
      <GridItem my={'auto'} ml={'auto'}>
        <Ellipsis />
      </GridItem>
    </Grid>
  );
}

const ChatSection = ({msgs, seen, pfp}: {pfp?: string, seen: boolean, msgs: ChatMessages[]}) => {
  const ref = useRef<HTMLDivElement|null>(null);
  const { data } = useUser()

  const mutation = useSeenMessage()

  useEffect(() => {
    ref.current?.scrollTo(0, ref.current.scrollHeight);

    // if last msg is not seen and not mine (i.e. not from me )
    // setting it to seen on backend
    if (msgs.some((msg: ChatMessages) => (!msg.seen && msg.from !== data?._id))){
      mutation.mutate({ from: msgs.find(msg => msg.from !== data?._id)?.from ?? '' })
    }
  }, [msgs]);

  return(
    <Flex ref={ref} flexDir={'column'} className="chat-box" overflowY={'scroll'}>
      {msgs.map(({from, msg}: any, i: number) => {
        const me = from === data?._id
        return(
          <Flex 
          key={i}
          justifyContent={me ? 'flex-end' : 'flex-start'} 
          mb={3}
          position={'relative'}
          className={`chat ${me ? 'right' : 'left'}`}
          >
            {!me ? <Avatar src={pfp ? `${process.env.REACT_APP_BASE_URL}/${pfp}` : defaultPfp} height={'35px'} width={'35px'} /> : <></>}
            <Flex
            width={'fit-content'}
            flexWrap={"wrap"}
            marginRight={me ? '12px' : '0px'}
            marginLeft={!me ? '20px' : '0px'}
            borderRadius={'4px'}
            bgColor={me ? 'green.500' : 'rgba(247, 247, 247, 1)'}  
            color={me ? 'white' : 'black'}
            maxWidth={['100%', '50%']}
            padding={3}
            >
            {msg}
            </Flex>
          </Flex>
        )}
      )}
      <Caption2 
        textAlign="right" 
        mr="3" 
        mb="2" 
        color='grey'
      >
        {seen ? 'Seen' : ''}
      </Caption2>
    </Flex>
  );
}

const ChatInput = ({chatUserId}: {chatUserId:string}) => {
  const [msg, setMsg] = useState<string>('')

  const mutation = useSendMessage()

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      sendMsg()
    }
  }

  const sendMsg = () => {
    if (!msg){
      return
    }
    mutation.mutate({msg, to: chatUserId}, {
      onSettled: () => setMsg('')
    })
  }

  return (
    <InputGroup marginTop={'auto'}>
      <Input 
        value={msg} 
        onChange={e => setMsg(e.target.value)} 
        placeholder='Message...' 
        onKeyDown={handleKeyDown}
      />
      <InputRightElement 
        cursor={'pointer'} 
        onClick={sendMsg} 
      >
        <SendIcon  />
      </InputRightElement>
    </InputGroup>
  );
}

const ChatBox = ({chat} : {chat: any}) => {
  const { isFetching, data: msgs } = useGetMessages(chat._id)
  const qc = useQueryClient();

  useEffect(() => {
    socket.on('message', (data) => {
      try{
        const msg = JSON.parse(data)
        qc.setQueryData(
          ['messages', msg.from], 
          (oldData: ChatMessages[]) => 
            oldData ? oldData.concat(msg) : [msg]
        )
      }
      catch(e){
        console.log(e)
      }
    })

    socket.on('seen', (data) => {
      try{
        qc.setQueryData(
          ['messages', data],
          (oldData: ChatMessages[]) => {
            if (oldData){
              let newD = []
              for (let i = 0; i< oldData.length; i++){
                newD.push({
                  ...oldData[i],
                  seen: true
                })                
              }
              return newD
            }
            else{
              return oldData
            }
          }
        )
      }
      catch(e){
        console.log(e)
      }
    })
  }, []);

  let isSeen = msgs ? msgs.length > 0 ? msgs[msgs.length - 1].seen : false : false

  return(
    <Flex width={'100%'} flexDir={'column'} p={[1, 4]} height={'100%'}>
      <ChatHeader chat={{name: chat.name, pfp: chat.profilepic}}  />
      <Divider orientation="horizontal" my={[1, 2]} />
      {isFetching ? (
        <Spinner />
      ) : (
        <ChatSection seen={isSeen} pfp={chat.profilepic} msgs={msgs ?? []} />
      )}
      <ChatInput chatUserId={chat._id} />
    </Flex>
  );
}
 
export const ChatView = () => {
  let [selected, setSelected] = useState<{
    _id: string;
    name: string;
    profilepic?: string;
  }|null>(null);

  const { chatId } = useParams()

  const { data: chats } = useGetChats()

  useEffect(() => {
    if (chats){
      const selectedChat = chats.find(chat => chat._id === chatId)
      if (selectedChat){
        setSelected(selectedChat)
      }
    }
  }, [chatId, chats])

  return(
    <Flex 
      height={'100%'} maxH={'100%'} minH={'100%'} 
      border={'1px solid rgba(216, 220, 224, 1)'} 
      borderRadius={'16px'} padding={['4px', '16px']}
    >
      <Show above="md">
       <Flex flex={1} p={[1, 2]} bgColor={'rgba(247, 247, 247, 1)'} borderRadius={'8px'} >
         <Flex width={'100%'} className="chats" flexDir={'column'} overflowY={'scroll'}>
           <ChatPreview chats={chats ?? []} selected={selected?._id} />
         </Flex>
       </Flex>
       <Flex maxHeight={'100%'} flex={3} flexDir={'column'} maxH={'100%'}>
         {selected ? <ChatBox chat={selected} /> : <></>}
       </Flex>
      </Show>
      <Show below="sm">
        {selected ? (
          <Flex maxHeight={'100%'} flex={1} flexDir={'column'} maxH={'100%'}>
            <ChatBox chat={selected} /> 
          </Flex>
        ) : (
          <Flex flex={1} p={2} bgColor={'rgba(247, 247, 247, 1)'} borderRadius={'8px'} >
            <Flex width={'100%'} className="chats" flexDir={'column'} overflowY={'scroll'}>
              <ChatPreview chats={chats ?? []} selected={''} />
            </Flex>
          </Flex>
        )}
      </Show>
    </Flex>
  );
}