import { Flex } from "@chakra-ui/react"
import { InputField } from "components/molecules"
import { FormikValues, useFormikContext } from "formik"

export const EditNameAge = () => {
  const { values }: FormikValues = useFormikContext()
  
  return(
    <>
      <Flex>
      <InputField
        name="name"
        value={values.name}
        isRequired={true}
        label="Full Name"
        placeholder="Jonas"
      />
      </Flex>
      <Flex>
        <InputField
          name="age"
          value={values.age}
          isRequired={true}
          label="Age"
          type="number"
          placeholder="25"
        />
      </Flex>
   
    </>
  )
}