import { OptionGroup } from "components/molecules"
import { FormikValues, useFormikContext } from "formik"
import { interestOptions } from "utils"

export const EditInterests = () => {
  const { values, setFieldValue } : FormikValues = useFormikContext()
  
  return(
    <OptionGroup 
    selected={values.tenantData.descriptions}
    options={interestOptions}
    onChange={(items: string[]) => {console.log(items);setFieldValue('tenantData.descriptions', items)}}
    columns={[2]}
    fontSize="sm"
    />
  )
}