import { Grid, GridItem } from "@chakra-ui/react";
import { InputField } from "components/molecules";
import { FormikValues, useFormikContext } from "formik";

export const EditNearby = () => {
  const { values }: FormikValues = useFormikContext();

  return (
    <Grid
      width={"100%"}
      templateRows="repeat(1, 1fr)"
      templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
      gap={5}
    >
      <GridItem colSpan={1}>
        <InputField
          name="uni_min"
          value={values.uni_min}
          isRequired={true}
          label="University (mins)"
          type="number"
          placeholder="3"
        />
      </GridItem>
      <GridItem colSpan={1}>
        <InputField
          name="bus_min"
          value={values.bus_min}
          isRequired={true}
          label="Bus Stop (mins)"
          type="number"
          placeholder="3"
        />
      </GridItem>
      <GridItem colSpan={1}>
        <InputField
          name="cc_min"
          value={values.cc_min}
          isRequired={true}
          label="City Centre (mins)"
          type="number"
          placeholder="3"
        />
      </GridItem>
    </Grid>
  );
};
