import {
  Avatar,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Image,
  Spacer,
  Text,
  theme,
} from "@chakra-ui/react";
import {
  Body0,
  Caption2,
  PrimaryButton,
  SelectedItems,
  Title1,
  Title3,
  Title4,
} from "components/molecules";
import {
  CookingPot,
  EditIcon,
  PawPrint,
  SquareArrowOutUpRight,
  Users,
} from "lucide-react";
import { DetailBar } from "../../Dashboard/DetailBar";
import {
  Cleanliness,
  Cook,
  dateFormatter,
  GenderEnum,
  getDurationOfStay,
  Occupation,
  Pets,
  PHOTO_HIGHLIGHTS_MAX_IMAGES,
  Smoke,
  Socialize,
} from "utils";
import Location from "assets/images/LocationIcon.svg";
import Language from "assets/images/LanguageIcon.svg";
import Education from "assets/images/EducationIcon.svg";
import Budget from "assets/images/euro.svg";
import Phone from "assets/images/PhoneNumber.svg";
import Profession from "assets/images/ProfessionIcon.svg";
import Gender from "assets/images/GenderIcon.svg";
import GlobeIcon from "assets/images/GlobeIcon.svg";
import SmokeIcon from "assets/images/SmokeIcon.svg";
import CleanlinessIcon from "assets/images/CleanlinessIcon.svg";
import HomeIcon from "assets/images/HomeIcon.svg";
import { TenantType } from "types";
import { EditModal } from "./EditModals";
import { ChangeEvent, useRef, useState } from "react";
import { countries_map, language_mappings } from "utils/data";
import { useRemoveHighlight, useUpdatePfp } from "lib";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { useAddHighlight } from "lib/mutations/tenant/useAddHighlight";

export const TenantProfileView = ({ tenant }: { tenant: TenantType }) => {
  const {
    email,
    name,
    tenantData,
    age,
    location: currLocation,
    language,
    gender,
    profilepic,
  } = tenant;

  const {
    photoHighlights,
    moveInDate,
    moveOutDate,
    occupation,
    city: preferredLocation,
    smoke,
    cleanliness,
    pets,
    socialize,
    cook,
    descriptions,
    budget,
    bio,
    phoneNumber,
  } = tenantData;

  const [editModal, setEditModal] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const imgRef = useRef<HTMLInputElement | null>(null);
  const imgHighlightRef = useRef<HTMLInputElement | null>(null);

  const uploadMutation = useUpdatePfp();
  const uploadHighlightMutation = useAddHighlight();
  const removeHighlightMutation = useRemoveHighlight();

  const handleOpen = (id: string) => {
    setEditId(id);
    setEditModal(true);
  };

  const handleClose = () => {
    setEditModal(false);
  };

  const fileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (files.length > 0) {
        uploadMutation.mutate(files[0]);
      }
    }
  };

  const fileChangeHighlight = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (files.length > 0) {
        uploadHighlightMutation.mutate(files[0]);
      }
    }
  };

  const fileRemoveHighlight = (index: number) => {
    removeHighlightMutation.mutate(index);
  };

  const editPfp = () => (imgRef.current ? imgRef.current.click() : "");
  return (
    <>
      {/* <Flex> */}
      <Flex
        flexDir={"column"}
        border={"1px solid rgba(216, 220, 224, 1)"}
        borderRadius={"8px"}
        padding={"25px 10px"}
        alignItems={"center"}
      >
        <input
          ref={imgRef}
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={fileChange}
        />
        <Avatar
          size={"xl"}
          onClick={editPfp}
          src={`${process.env.REACT_APP_BASE_URL}/${profilepic}`}
        />
        <Flex marginTop={"20px"} marginBottom={"10PX"} flexDir={"column"}>
          <Text px={5} alignItems={"left"} textStyle={"book"}>
            {name}
          </Text>
          <Text
            px={5}
            alignItems={"left"}
            fontSize={"small"}
            textStyle={"light"}
          >
            {email}
          </Text>
        </Flex>
        <PrimaryButton px={5} style={{ width: "100%", borderRadius: "25px" }}>
          Edit Profile - 60%
        </PrimaryButton>
      </Flex>
      <Flex
        flexDir={"column"}
        border={"1px solid rgba(216, 220, 224, 1)"}
        width={"100%"}
        ml={"10px"}
        borderRadius={"8px"}
        padding={"20px 50px"}
        overflow={"scroll"}
        className="hide-scrollbar"
      >
        <Flex
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
          my={5}
        >
          <Flex flexDir={"row"} alignItems={"baseline"}>
            <Title3>{name}</Title3>
            <Spacer mr={4} />
            <Title4 color="black.800">age: {age}</Title4>
            <Flex
              ml={2}
              onClick={() => handleOpen("name-age")}
              mb={"auto"}
              mt={1}
              cursor={"pointer"}
              alignItems={"flex-start"}
            >
              <EditIcon size={"14px"} />
            </Flex>
          </Flex>
          <Flex flexDir={"column"} alignItems={"center"}>
            <Box
              cursor={"pointer"}
              borderRadius={"50%"}
              backgroundColor={"rgba(0,0,0,0.05)"}
              padding={"2"}
            >
              <SquareArrowOutUpRight size={"16px"} />
            </Box>
            <Caption2 color={theme.colors.black[800]}>Share</Caption2>
          </Flex>
        </Flex>

        <Flex flexDir={"column"} marginBottom={"20PX"}>
          <Text textStyle={"bold"} fontSize="medium">
            Bio
            <Flex
              ml={2}
              display={"inline-flex"}
              onClick={() => handleOpen("bio")}
              mb={"auto"}
              mt={1}
              cursor={"pointer"}
              alignItems={"flex-start"}
            >
              <EditIcon size={"14px"} />
            </Flex>
          </Text>
          <Body0>{bio ? bio : "--"}</Body0>
        </Flex>

        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
          gap={5}
        >
          <DetailBar
            openModal={() => handleOpen("movein")}
            icon={HomeIcon}
            name="Move-in date"
            value={<>{dateFormatter(moveInDate)}</>}
          />
          <DetailBar
            openModal={() => handleOpen("location")}
            icon={Location}
            name="Preferred location"
            value={preferredLocation}
          />
          <DetailBar
            openModal={() => handleOpen("movein")}
            icon={HomeIcon}
            name="Duration of Stay"
            value={getDurationOfStay(moveInDate, moveOutDate)}
          />
          <DetailBar
            openModal={() => handleOpen("language")}
            icon={Language}
            name="Languages"
            value={language
              ?.split(",")
              .map((l: string) => language_mappings[l])
              .join(",")}
          />

          <DetailBar
            openModal={() => handleOpen("countries")}
            icon={Location}
            name="Home Country"
            value={countries_map[currLocation]}
          />
          <DetailBar
            openModal={() => handleOpen("occupation")}
            icon={Education}
            name="Education"
            value={Occupation[occupation]}
          />
          <DetailBar
            openModal={() => handleOpen("occupation")}
            icon={Profession}
            name="Profession"
            value={Occupation[occupation]}
          />
          <DetailBar
            openModal={() => handleOpen("countries")}
            icon={GlobeIcon}
            name="From"
            value={currLocation}
          />
          <DetailBar
            openModal={() => handleOpen("gender")}
            icon={Gender}
            name="Gender"
            value={GenderEnum[gender]}
          />
          <DetailBar
            openModal={() => handleOpen("budget")}
            icon={Budget}
            name="Budget"
            value={`${budget}€`}
          />
          <DetailBar
            openModal={() => handleOpen("phone")}
            icon={Phone}
            name="Phone"
            value={`${phoneNumber}`}
          />
        </Grid>
        <Divider orientation="horizontal" my={5} />

        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
          gap={5}
        >
          <GridItem colSpan={[1, 2]}>
            <Title1>Additional Information</Title1>
          </GridItem>
          <DetailBar
            openModal={() => handleOpen("smoke")}
            icon={SmokeIcon}
            name="Smoke"
            value={Smoke[smoke]}
          />
          <DetailBar
            openModal={() => handleOpen("cleanliness")}
            icon={CleanlinessIcon}
            name="Cleanliness"
            value={Cleanliness[cleanliness]}
          />
          <DetailBar
            openModal={() => handleOpen("pets")}
            img={<PawPrint />}
            name="Pets"
            value={Pets[pets]}
          />
          <DetailBar
            openModal={() => handleOpen("socialize")}
            img={<Users />}
            name="Socializing"
            value={Socialize[socialize]}
          />
          <DetailBar
            openModal={() => handleOpen("cooking")}
            img={<CookingPot />}
            name="Cooking"
            value={Cook[cook]}
          />
        </Grid>
        <Divider orientation="horizontal" my={5} />

        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
          gap={2}
        >
          <GridItem colSpan={[1, 2]}>
            <Title1 marginBottom={"20PX"}>
              Interests
              <Flex
                ml={2}
                display={"inline-flex"}
                onClick={() => handleOpen("interests")}
                mb={"auto"}
                mt={1}
                cursor={"pointer"}
                alignItems={"flex-start"}
              >
                <EditIcon size={"14px"} />
              </Flex>
            </Title1>
          </GridItem>
        </Grid>
        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(2, 1fr)`, `repeat(4, 1fr)`]}
          gap={5}
        >
          <SelectedItems items={descriptions ?? []} />
        </Grid>
        <Divider orientation="horizontal" my={5} />

        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(5, 1fr)`]}
          gap={5}
        >
          <GridItem colSpan={[1, 5]}>
            <Title1>Photo Highlights</Title1>
          </GridItem>
          <input
            ref={imgHighlightRef}
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={fileChangeHighlight}
          />
          {Array.from({ length: PHOTO_HIGHLIGHTS_MAX_IMAGES }).map(
            (_, index) => (
              <GridItem key={index} position="relative">
                {photoHighlights?.[index] ? (
                  <Box position="relative">
                    <Image
                      src={
                        process.env.REACT_APP_BASE_URL +
                        "/" +
                        photoHighlights[index]
                      }
                      alt={`Thumbnail ${index + 1}`}
                      borderRadius="8px"
                      boxSize="150px"
                      objectFit="cover"
                    />
                    <IconButton
                      aria-label="Remove Image"
                      icon={<CloseIcon />}
                      size="xs"
                      position="absolute"
                      top="4px"
                      right="4px"
                      backgroundColor="rgba(0,0,0,0.6)"
                      color="white"
                      _hover={{ backgroundColor: "red.500" }}
                      onClick={() => fileRemoveHighlight(index)}
                    />
                  </Box>
                ) : (
                  <Box
                    borderRadius="8px"
                    boxSize="150px"
                    backgroundColor="gray.200"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={() => imgHighlightRef.current?.click()}
                  >
                    <Icon as={AddIcon} boxSize={6} color="gray.500" />
                  </Box>
                )}
              </GridItem>
            )
          )}
        </Grid>

        <Divider orientation="horizontal" my={5} />
        {editModal && (
          <EditModal
            user={tenant}
            id={editId}
            isOpen={editModal}
            handleClose={handleClose}
          />
        )}
      </Flex>
    </>
  );
};
