import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TenantType } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useUpdatePfp = () => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['updatePgp'],
    mutationFn: (pfp: File) => {
      const fd = new FormData()
      fd.append('pfp', pfp, pfp.name)
      return api.patch('/users/update_pfp', {body: fd}, true);
    },
    onSuccess: (res: any, updatedTenant) => {
      qC.setQueryData(
        ['authenticated-user'],
        (oldData: TenantType) => {
          if (oldData){
            return {...oldData, profilepic: res.data}
          }
          return oldData
        }
      )      
      useNotifications.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: `Successfully updated profile`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to edit profile',
        message: error.message ? error.message : 'Try again',
      });
    }
  })
  return mutation;
}