import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "lib";
import { useNotifications } from "components/organisms";
import { PropertyType } from "types";

export const useRemoveRoomPhoto = (propertyId: string) => {
  const qC = useQueryClient();

  const mutation = useMutation({
    mutationKey: ["removeRoomPhoto"],
    mutationFn: async (image: number) => {
      return api.delete(
        `/property/roomphoto/${propertyId}`,
        { body: JSON.stringify({ image }) },
        true,
        "json"
      );
    },
    onSuccess: (_, image) => {
      qC.setQueryData(["property", propertyId], (oldData: PropertyType) => {
        if (oldData) {
          return {
            ...oldData,
            roomPhotos: oldData.roomPhotos
              ? oldData.roomPhotos.filter((_, i) => i !== image)
              : [],
          };
        }
        return oldData;
      });
      useNotifications.getState().addNotification({
        type: "success",
        title: "Success",
        message: `Successfully updated room photos`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: "error",
        title: "Failed to update room photos",
        message: error.message ? error.message : "Try again",
      });
    },
  });
  return mutation;
};
