import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { PrimaryButton, SecondaryButton } from "components/molecules";
import { Form, Formik } from "formik";
import { PropertyType } from "types";
import { useEditProperty } from "lib";
import {
  EditAmenities,
  EditLocation,
  EditNearby,
  EditOtherDetails,
  EditRoommates,
  EditRoomsize,
  EditTitle,
} from "./EditForms";

type EditModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  id: string;
  property: PropertyType;
};

const editTypes: { [key: string]: any } = {
  roomsize: {
    comp: EditRoomsize,
    header: "Edit Room Size",
    fieldsToSubmit: (values: any) => ({
      roomSize: values.roomSize,
    }),
  },
  roommates: {
    comp: EditRoommates,
    header: "Edit Total Roomates",
    fieldsToSubmit: (values: any) => ({
      roommates: values.roommates,
    }),
  },
  location: {
    comp: EditLocation,
    header: "Edit Location",
    fieldsToSubmit: (values: any) => ({
      location: values.location,
    }),
  },
  nearby: {
    comp: EditNearby,
    header: "Edit Nearby",
    fieldsToSubmit: (values: any) => ({
      cc_min: values.cc_min,
      bus_min: values.bus_min,
      uni_min: values.uni_min,
    }),
  },
  otherDetails: {
    comp: EditOtherDetails,
    header: "Edit Other Details",
    fieldsToSubmit: (values: any) => ({
      extraDescription: values.extraDescription,
    }),
  },
  amenities: {
    comp: EditAmenities,
    header: "Edit Amenities",
    fieldsToSubmit: (values: any) => ({
      extraDetails: values.extraDetails,
    }),
  },
  title: {
    comp: EditTitle,
    header: "Edit Title",
    fieldsToSubmit: (values: any) => ({
      title: values.title,
    }),
  },
};

export const EditModal = ({
  isOpen,
  handleClose,
  id,
  property,
}: EditModalProps) => {
  const { comp: EditFields, header, fieldsToSubmit } = editTypes[id];

  const mutation = useEditProperty(property._id);

  return (
    <Modal isOpen={isOpen} isCentered onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <Formik
            initialValues={property}
            validate={() => ({})}
            onSubmit={async (values) => {
              try {
                await mutation.mutateAsync(fieldsToSubmit(values), {
                  onSuccess: () => {
                    handleClose();
                  },
                  onError: (e: any) => {},
                });
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <Form>
              <ModalHeader>{header}</ModalHeader>
              <ModalBody>
                <EditFields />
              </ModalBody>
              <ModalFooter>
                <PrimaryButton
                  type="submit"
                  isDisabled={mutation.isPending}
                  width="100%"
                  ml="2"
                >
                  {mutation.isPending ? <Spinner /> : "Save"}
                </PrimaryButton>
                <SecondaryButton width="100%" ml="2" onClick={handleClose}>
                  Cancel
                </SecondaryButton>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
