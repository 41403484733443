import { useGetMyListings } from "lib"
import { useParams } from "react-router-dom"
import { ListingRow } from "./listingRow"

export const Body = () => {
  const {propertyId} = useParams()

  const { data: listings } = useGetMyListings(propertyId ?? "")
  
  return(
    <>
      {listings?.map(listing => (
        <ListingRow 
          listing={listing} 
          key={listing._id} 
        />  
      ))}
    </>
  )
}