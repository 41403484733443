import { Flex } from "@chakra-ui/react";
import { useUser } from "lib";
import { Spinner } from "components/atoms";
import { Navigate } from "react-router-dom";
import { StatusEnum } from "utils";
import { TenantProfileView } from "./Tenant";
import { RenterProfileView } from "./Renter";

export const ProfileView = () => {
  const { data: user, isFetching, status } = useUser();
  console.log(status);
  if (isFetching) {
    return (
      <Flex>
        <Spinner />
      </Flex>
    );
  }
  if (user) {
    return (
      <Flex height={"100%"}>
        {user.status === StatusEnum.Renter ? (
          <RenterProfileView renter={user} />
        ) : (
          <></>
        )}
        {user.status === StatusEnum.Tenant ? (
          <TenantProfileView tenant={user} />
        ) : (
          <></>
        )}
      </Flex>
    );
  } else {
    return <Navigate to="/login?redirectTo=/profile" />;
  }
};
