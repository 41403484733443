export const SignupCategory = {
  TENANT: {
    value: "tenant",
    offset: 5,
  },
  RENTER: {
    value: "renter",
    offset: 1,
  },
};

// time for which a notification is displayed (in secs)
export const notif_time = 5;

export const apartmentTitles = [
  "Cozy Room in Shared WG",
  "Bright WG Near City Center",
  "Affordable Shared Flat Option",
  "Modern Room in a WG",
  "Spacious WG Close to Transport",
  "Shared Apartment with Great Views",
  "Charming WG in Quiet Area",
  "Friendly Flatshare with Amenities",
  "Stylish Room in Shared Home",
  "Central WG with Big Kitchen",
];

export const interestOptions = [
  "Adventurous",
  "Extrovert",
  "Introvert",
  "Music Lover",
  "Movie Lover",
  "Foodie",
  "Tech Enthusiast",
  "Traveler",
  "Cyclist",
  "Gamer",
  "Fitness Fanatic",
  "Nature Lover",
  "Photographer",
  "Yoga Practitioner",
  "Pet Owner",
  "DIY Crafter",
  "Art Lover",
  "Social Butterfly",
  "Avid Reader",
  "Sports Fan",
  "Netflix",
  "Funny",
];

export const amenities = [
  "Shared Bathroom",
  "Private Bathroom",
  "Kitchen",
  "Living Room",
  "Dining Area",
  "Washing Machine",
  "Microwave",
  "Internet (Wi-Fi)",
  "Fridge",
  "Freezer",
  "Oven",
  "Induction Cooktop",
  "Dishwasher",
  "Vacuum Cleaner",
  "Heating System",
  "Cellar",
  "Balcony",
  "Parking",
];

export const PHOTO_HIGHLIGHTS_MAX_IMAGES = 5; // Maximum number of placeholders
export const ROOM_PHOTOS_MAX_IMAGES = 5; // Maximum number of placeholders
