import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PropertyType } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useAddRoomPhoto = (propertyId: string) => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["addRoomPhoto"],
    mutationFn: (image: File) => {
      const fd = new FormData();
      fd.append("roomphoto", image, image.name);
      return api.post(`/property/roomphoto/${propertyId}`, { body: fd }, true);
    },
    onSuccess: (res: any) => {
      qC.setQueryData(["property", propertyId], (oldData: PropertyType) => {
        if (oldData) {
          return {
            ...oldData,
            roomPhotos: oldData.roomPhotos
              ? oldData.roomPhotos.concat([res.data])
              : [res.data],
          };
        }
        return oldData;
      });
      useNotifications.getState().addNotification({
        type: "success",
        title: "Success",
        message: `Successfully updated room photos`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: "error",
        title: "Failed to update room photos",
        message: error.message ? error.message : "Try again",
      });
    },
  });
  return mutation;
};
