import { OptionGroup } from "components/molecules";
import { FormikValues, useFormikContext } from "formik";
import { amenities } from "utils";

export const EditAmenities = () => {
  const { values, setFieldValue }: FormikValues = useFormikContext();

  return (
    <OptionGroup
      selected={values.extraDetails}
      options={amenities}
      onChange={(items: string[]) => setFieldValue("extraDetails", items)}
      columns={[2]}
      fontSize="sm"
    />
  );
};
