import { Grid, GridItem, Image, Spacer } from "@chakra-ui/react";
import { ErrorText, InputField, RadioField } from "components/molecules";
import defaultPfp from "assets/images/defaultPfp.png";
import { FormLabel } from "components/atoms";
import { languages } from "utils";
import { Select } from "chakra-react-select"; // Import Select from chakra-react-select
import { useRef } from "react";
import { FormikValues, useFormikContext } from "formik";

export const EditRoommateDetailForm = () => {
  let { values, setFieldValue, errors, touched }: FormikValues =
    useFormikContext();
  let imgInput = useRef<HTMLInputElement | null>(null);

  const fileChange = (e: any) => {
    if (e.target.files.length > 0) {
      setFieldValue(`pfp`, e.target.files[0]);
    }
  };

  const photoUploaded = () => values.pfp;

  const getObjURL = () =>
    typeof values.pfp === "string"
      ? `${process.env.REACT_APP_BASE_URL}/${values.pfp}`
      : URL.createObjectURL(values.pfp);

  const hasError = () => errors.pfp && touched.pfp;

  return (
    <Grid
      templateRows="repeat(1, 1fr)"
      templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)"]}
      gap={4}
    >
      <GridItem colSpan={[1, 2]} rowSpan={1}>
        <Image
          src={photoUploaded() ? getObjURL() : defaultPfp}
          borderRadius={photoUploaded() ? "full" : "initial"}
          cursor={"pointer"}
          width={"125px"}
          height={"125px"}
          objectFit={"cover"}
          onClick={() => (imgInput?.current ? imgInput.current?.click() : "")}
        />
        {hasError() ? <ErrorText>{errors.pfp}</ErrorText> : <></>}
        <input
          ref={imgInput}
          onChange={fileChange}
          style={{ display: "none" }}
          type="file"
          accept="image/png,image/jpeg,image/jpg"
        />
      </GridItem>
      <GridItem colSpan={1} height={"fit-content"}>
        <InputField
          name={`name`}
          isRequired={true}
          label="Full Name"
          placeholder="Jonas"
          value={values.name}
        />
      </GridItem>
      <GridItem colSpan={1} height={"fit-content"}>
        <InputField
          name={`age`}
          isRequired={false}
          label="Age"
          type="number"
          placeholder="25"
          value={values.age}
        />
      </GridItem>
      <GridItem colSpan={2}>
        <FormLabel htmlFor={`language`} textStyle="medium">
          {" "}
          I speak
        </FormLabel>

        <Select
          name={`language`}
          // value={values.property?.roommateDetails[i].language}
          options={languages}
          placeholder="Select languages"
          isMulti
          closeMenuOnSelect={false}
          selectedOptionStyle="check" // Optional: Highlight selected items
          value={languages.filter((lang) => {
            // console.log(values.property?.roommateDetails)
            return values.language?.includes(lang.value);
          })}
          onChange={(selected) => {
            const selectedValues = selected.map((option) => option.value);
            setFieldValue(`language`, selectedValues.join(","));
          }}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <RadioField
          isRequired={false}
          label="Gender"
          options={{ 1: "Male", 2: "Female", 3: "Other" }}
          name={`gender`}
          value={values.gender ? values.gender.toString() : values.gender}
        />
      </GridItem>
      <Spacer flex={0} my={3} />
    </Grid>
  );
};
