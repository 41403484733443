import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PropertyType, TenantType, User } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useEditProperty = (id: string) => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["updateProperty", id],
    mutationFn: (property: Partial<PropertyType>) => {
      return api.patch(
        `/property/${id}`,
        { body: JSON.stringify(property) },
        true,
        "json"
      );
    },
    onSuccess: (res: any, data: Partial<PropertyType>) => {
      qC.setQueryData(["property", id], (oldData: PropertyType) => {
        if (oldData) {
          return { ...oldData, ...data };
        }
        return oldData;
      });
      useNotifications.getState().addNotification({
        type: "success",
        title: "Success",
        message: `Successfully updated property`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: "error",
        title: "Failed to edit property",
        message: error.message ? error.message : "Try again",
      });
    },
  });
  return mutation;
};
