import { Flex, GridItem, Text } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

export const SelectedItems = ({items}: {items: string[]}) => (
  <>
    {items.map((detail, index) => (
      <GridItem key={index} colSpan={1}>
        <Flex alignItems="center">
          <CheckIcon color="green.500" marginRight={2} />
          <Text fontSize="sm">{detail}</Text>
        </Flex>
      </GridItem>
    ))}
  </>
)