import {
  Text,
  Avatar,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Hide,
  VStack,
} from "@chakra-ui/react";
import {
  Body1,
  Swiper,
  Title1,
  Body4,
  RoomDetail,
  SelectedItems,
} from "components/molecules";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import RoomSize from "assets/images/RoomSizeIcon.svg";
import Location from "assets/images/LocationIcon.svg";
import Roommate from "assets/images/RoommateIcon.svg";
import Calendar from "assets/images/CalendarIcon.svg";
import SearchingFor from "assets/images/search.svg";
import Deposit from "assets/images/DepositIcon.svg";
import SchoolIcon from "assets/images/SchoolIcon.svg";
import BusIcon from "assets/images/BusIcon.svg";
import BuildingIcon from "assets/images/BuildingIcon.svg";
import { ListingType } from "types";
import {
  language_mappings,
  apartmentTitles,
  dateFormatter,
  GenderEnum,
  getAddressValue,
  getAddressString,
} from "utils";
import { useGetProperty } from "lib";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MarkerIcon from "leaflet/dist/images/marker-icon.png";
import MarkerShadow from "leaflet/dist/images/marker-shadow.png";
import { ShareIcon } from "lucide-react";
import { useNotifications } from "components/organisms";

let DefaultIcon = L.icon({
  iconUrl: MarkerIcon,
  shadowUrl: MarkerShadow,
  iconAnchor: [12, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;

export const ApartmentPost = forwardRef(
  (
    {
      listing,
      styles = {},
    }: {
      listing: ListingType;
      styles?: React.CSSProperties;
    },
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    const {
      rent,
      moveInDate,
      moveOutDate,
      gender,
      age,
      securityDeposit,
      property: propertyId,
      _id: listingId,
    } = listing;

    let { data: property } = useGetProperty(propertyId);

    const [geoCoords, setGeoCoords] = useState<{
      lat: number;
      lon: number;
    } | null>(null);

    // Function to fetch geocoordinates
    const fetchGeocoordinates = async (address: string) => {
      const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        address
      )}&format=json&addressdetails=1`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data && data.length > 0) {
          // Use the first result's latitude and longitude
          setGeoCoords({
            lat: parseFloat(data[0].lat),
            lon: parseFloat(data[0].lon),
          });
        } else {
          console.error("No geocoordinates found for the address.");
        }
      } catch (error) {
        console.error("Error fetching geocoordinates:", error);
      }
    };

    useEffect(() => {
      if (property?.location) {
        try {
          const propObj = JSON.parse(property.location);
          if (!!propObj.lat && !!propObj.lon) {
            setGeoCoords({
              lat: parseFloat(propObj.lat),
              lon: parseFloat(propObj.lon),
            });
          } else {
            const adrsStr = getAddressString(propObj);
            fetchGeocoordinates(adrsStr ?? "");
          }
        } catch (e) {
          fetchGeocoordinates(property.location);
        }
      }
    }, [property?.location]);

    const handleShare = () => {
      const shareUrl = `${window.location.origin}/?listing=${listingId}`;

      if (navigator.share) {
        // Use Web Share API for supported devices
        navigator
          .share({
            title: "Check out this property!",
            text: `Take a look at this property for ${rent}€ in ${property?.location}!`,
            url: shareUrl,
          })
          .then(() => console.log("Thanks for sharing!"))
          .catch((error) => console.error("Error sharing", error));
      } else {
        // Fallback for unsupported devices
        navigator.clipboard.writeText(shareUrl).then(() => {
          useNotifications.getState().addNotification({
            type: "success",
            title: "Link copied successfully",
            message: `Share it in your circle to increase the reach of your posting`,
          });
        });
      }
    };

    if (property) {
      const {
        roomPhotos: pictures,
        roomSize,
        roommates,
        roommateDetails,
        location,
        extraDetails,
        extraDescription,
        uni_min,
        cc_min,
        bus_min,
        title,
      } = property;
      const randomTitle =
        title ??
        apartmentTitles[Math.floor(Math.random() * apartmentTitles.length)];

      return (
        <Flex
          ref={ref}
          flexDir={["column", "row"]}
          alignItems={"center"}
          justifyContent={"center"}
          overflow={["none", "hidden"]}
          style={styles}
          paddingTop={[0, 10]}
          className="animate__animated animate__zoomIn"
        >
          <Flex
            width={["100vw", "75vw"]}
            flexDir={["column", "row"]}
            border={[
              "0px solid rgba(216, 220, 224, 1)",
              "1px solid rgba(216, 220, 224, 1)",
            ]}
            borderBottom={"none"}
            borderRadius={["0px", "16px"]}
            borderBottomLeftRadius={"0"}
            borderBottomRightRadius={"0"}
            padding={["0px", "16px"]}
            maxH={"100%"}
            overflow={["scroll", "none"]}
            className="hide-scrollbar"
            position="relative" // Enable relative positioning for the container
          >
            {/* Floating Share Button */}
            <Hide below="md">
              <Box
                position="absolute"
                bottom="50px"
                right="30px"
                zIndex="9999"
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor={"#F7F7F7"}
                borderRadius="50%"
                width="48px"
                height="48px"
                boxShadow="lg"
                cursor="pointer"
                _hover={{ backgroundColor: "#E2E8F0" }}
                onClick={handleShare}
              >
                <ShareIcon size={"20px"} />
              </Box>
            </Hide>

            {/* Room photos carousel */}
            <Swiper
              border="none"
              width="100%"
              height={["auto", "72vh"]}
              photoUrls={pictures?.map(
                (r: string) => `${process.env.REACT_APP_BASE_URL}/${r}`
              )}
            />

            <VStack
              padding={6}
              width={"100%"}
              maxH={"100%"}
              className="apartment-details"
              overflowX={"hidden"}
              overflowY={["scroll"]}
            >
              <Flex
                justifyContent={"space-between"}
                width={"100%"}
                alignItems={"center"}
              >
                <Flex align="center" gap={2}>
                  <Text textStyle="medium" fontSize="2xl">
                    {randomTitle}
                  </Text>
                </Flex>
                <Box
                  backgroundColor={"#F7F7F7"}
                  padding={"8px 16px"}
                  borderRadius={"24px"}
                  textAlign="center"
                >
                  <Text textStyle="medium" fontSize="2xl" lineHeight="1.2">
                    {rent}€
                  </Text>
                  <Text fontSize="sm" color="gray.500" marginTop="2px">
                    warm rent
                  </Text>
                </Box>
              </Flex>
              ;
              <Divider orientation="horizontal" margin={3} />
              <Grid
                width={"100%"}
                templateRows="repeat(1, 1fr)"
                templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
                gap={5}
              >
                <RoomDetail
                  icon={RoomSize}
                  name="Room Size"
                  value={
                    <>
                      {roomSize} m<sup>2</sup>
                    </>
                  }
                />
                <RoomDetail
                  icon={Calendar}
                  name="Availability"
                  value={
                    <>
                      {dateFormatter(moveInDate, "ASAP")} -{" "}
                      {dateFormatter(moveOutDate, "Unlimited")}
                    </>
                  }
                />
                <RoomDetail
                  icon={Roommate}
                  name="Roommates"
                  value={<>{roommates}</>}
                />
                <RoomDetail
                  icon={SearchingFor}
                  name="Searching For"
                  value={
                    <>
                      {GenderEnum[gender ? gender : 3]} | 18 - {age} Years old
                    </>
                  }
                />
                <RoomDetail
                  icon={Location}
                  name="Location"
                  value={<>{getAddressValue(location)}</>}
                />
                <RoomDetail
                  icon={Deposit}
                  name="Deposit"
                  value={<>{securityDeposit}€</>}
                />
              </Grid>
              <Divider orientation="horizontal" padding={3} />
              {roommateDetails.length > 0 ? (
                <Grid
                  width={"100%"}
                  templateRows="repeat(1, 1fr)"
                  templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
                  gap={2}
                >
                  <GridItem colSpan={[1, 2]}>
                    <Title1>Roommates</Title1>
                  </GridItem>
                  {roommateDetails.map(
                    ({ name, age, gender, language, pfp }: any, i: number) => (
                      <GridItem
                        key={`${name}-${i}`}
                        backgroundColor={"rgba(0,0,0,0.05)"}
                        borderRadius={"8px"}
                        padding={3}
                        colSpan={1}
                      >
                        <Flex>
                          <Avatar
                            marginRight={"10px"}
                            height={"50px"}
                            width={"50px"}
                            src={`${process.env.REACT_APP_BASE_URL}/${pfp}`}
                          />
                          <VStack alignItems={"flex-start"} gap={0}>
                            <Body1>{name}</Body1>
                            {age ? <Body4>Age: {age}</Body4> : <></>}
                            {language ? (
                              <Body4>
                                Speaks:{" "}
                                {language
                                  .split(",")
                                  .map((l: any) => language_mappings[l])
                                  .join(",")}
                              </Body4>
                            ) : (
                              <></>
                            )}
                          </VStack>
                        </Flex>
                      </GridItem>
                    )
                  )}
                </Grid>
              ) : (
                <></>
              )}
              {extraDetails?.length > 0 ? (
                <>
                  <Divider orientation="horizontal" padding={3} />
                  <Grid
                    width={"100%"}
                    templateRows="repeat(1, 1fr)"
                    templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
                    gap={5}
                  >
                    <GridItem colSpan={[3, 3]}>
                      <Title1>Good to know</Title1>
                    </GridItem>
                    <SelectedItems items={extraDetails} />
                  </Grid>
                </>
              ) : (
                <></>
              )}
              {extraDescription ? (
                <>
                  <Divider orientation="horizontal" padding={3} />
                  <Grid
                    width={"100%"}
                    templateRows="repeat(1, 1fr)"
                    templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
                    gap={5}
                  >
                    <GridItem colSpan={3}>
                      <Title1>Other details</Title1>
                    </GridItem>
                    <GridItem colSpan={3}>
                      <Text fontSize="sm">{extraDescription}</Text>
                    </GridItem>
                  </Grid>
                </>
              ) : (
                <></>
              )}
              <Divider orientation="horizontal" padding={3} />
              <Grid
                width={"100%"}
                templateRows="repeat(1, 1fr)"
                templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
                gap={5}
              >
                <GridItem colSpan={[1, 2]}>
                  <Title1>What's nearby?</Title1>
                </GridItem>
                <RoomDetail
                  icon={SchoolIcon}
                  name="University"
                  value={<>{uni_min ? `${uni_min} mins away` : `Not known`}</>}
                />
                <RoomDetail
                  icon={BusIcon}
                  name="Bus stop"
                  value={<>{bus_min ? `${bus_min} mins away` : `Not known`}</>}
                />
                <RoomDetail
                  icon={BuildingIcon}
                  name="City center"
                  value={<>{cc_min ? `${cc_min} mins away` : `Not known`}</>}
                />
              </Grid>
              {geoCoords ? (
                <Box width="100%" height="300px" marginY={5}>
                  <MapContainer
                    center={[geoCoords.lat, geoCoords.lon]}
                    zoom={13}
                    scrollWheelZoom={false}
                    style={{
                      height: "300px",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[geoCoords.lat, geoCoords.lon]}>
                      <Popup>{location}</Popup>
                    </Marker>
                  </MapContainer>
                </Box>
              ) : (
                <Text>No map data available</Text>
              )}
            </VStack>
          </Flex>
        </Flex>
      );
    }
    return <></>;
  }
);
