import { Flex } from "@chakra-ui/react";
import { InputField } from "components/molecules";
import { FormikValues, useFormikContext } from "formik";

export const EditSecurityDeposit = () => {
  const { values }: FormikValues = useFormikContext();

  return (
    <Flex>
      <InputField
        name="securityDeposit"
        value={values.securityDeposit}
        isRequired={true}
        label="Security Deposit"
        type="number"
        placeholder="250"
      />
    </Flex>
  );
};
