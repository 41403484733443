import { dateFormatter, getAddressString, getAddressValue } from "utils";
import { Divider, Image } from "@chakra-ui/react";
import { ListingType, PropertyType } from "types";
import { ApplicationRow } from "../../ApplicationRow";
import { PrimaryButton, SecondaryButton } from "components/molecules";
import DefaultImg from "assets/images/photoHighlights-2c716dbb164304be.png";
import ViewIcon from "assets/images/ViewIcon.svg";
import ChatIcon from "assets/images/ChatIcon.svg";
import { Link } from "react-router-dom";

export const TenantAcceptedApplicationRow = ({
  listing,
  property,
  viewApartment,
}: {
  property: PropertyType;
  listing: ListingType;
  viewApartment: (l: ListingType) => void;
}) => {
  let { roomPhotos, location, roomSize, owner } = property;
  let { rent, moveInDate, moveOutDate } = listing;
  return (
    <ApplicationRow
      img={
        roomPhotos
          ? roomPhotos.length > 0
            ? `${process.env.REACT_APP_BASE_URL}/${roomPhotos[0]}`
            : DefaultImg
          : DefaultImg
      }
      main={getAddressValue(location)}
      subMain={
        <>
          {roomSize} m<sup>2</sup>
          <Divider mx="1" display={"inline"} orientation="vertical" />
          {rent}$
          <Divider display={"inline"} mx="1" orientation="vertical" />
          {dateFormatter(moveInDate)} - {dateFormatter(moveOutDate)}
        </>
      }
      buttons={
        <>
          <SecondaryButton
            mr={[0, 2]}
            mb={[2, 0]}
            padding={4}
            color="black"
            onClick={() => viewApartment(listing)}
          >
            <Image src={ViewIcon} mr={2} /> View Apartment
          </SecondaryButton>
          <Link to={"/app/chat/" + owner}>
            <PrimaryButton padding={4}>
              <Image src={ChatIcon} mr={2} /> Chat
            </PrimaryButton>
          </Link>
        </>
      }
    />
  );
};
