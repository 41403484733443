import { Flex } from "@chakra-ui/react";
import { useUser } from "lib";
import { Spinner } from "components/atoms";
import { Navigate } from "react-router-dom";
import { StatusEnum } from "utils";
import { MyProperty } from "./MyProperty";

export const MyPropertyLayout = () => {
  const { data: user, isFetching } = useUser();
  if (isFetching) {
    return (
      <Flex>
        <Spinner />
      </Flex>
    );
  }
  if (user) {
    if (user.status === StatusEnum.Renter) {
      return <MyProperty />;
    } else {
      return <Navigate to="/app/profile" />;
    }
  } else {
    return <Navigate to="/login?redirectTo=/profile" />;
  }
};

export * from "./Create";
