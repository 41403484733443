import { Box, Flex, List, ListItem } from "@chakra-ui/react"
import { InputField } from "components/molecules"
import { FormikValues, useFormikContext } from "formik"
import { useEffect, useState } from "react"
import { getAddressString, getAddressValue } from "utils"

export const EditLocation = () => {
  const { values, setFieldValue }: FormikValues = useFormikContext()
  const [suggestions, setSuggestions] = useState([]);
  
  const handleSelectSuggestion = (suggestion: any) => {
    setFieldValue('location', suggestion.value);
    setSuggestions([]);
  };

  // Fetch address suggestions from OpenStreetMap
  const fetchAddresses = async (input: string) => {
    if (input.length < 3) {
      setSuggestions([]);
      return;
    }

    const url = `https://nominatim.openstreetmap.org/search?q=${input}&countrycodes=de&format=json&addressdetails=1`;
    try {
      const response = await fetch(url, {
        headers: { 'User-Agent': 'YourAppName' }, // Replace 'YourAppName' with your application name
      });
      const data = await response.json();
      setSuggestions(
        data
          .filter((item: any) => item.address) // Ensure item contains address information
          .filter((item: any) => item.address.road) // Ensure item contains address information
          .map((item: any) => {
            return {
              label: getAddressString(item),
              value: JSON.stringify(item)
            };
          })
      );
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  useEffect(() => {
    try{
      const a = JSON.parse(values.location)
      console.log(a)
    }
    catch(e){
      const timeout = setTimeout(() => {
        fetchAddresses(values.location)
      }, 300);
  
      return () => {
        clearTimeout(timeout)
      }  
    }
  } , [values.location])


  return(
    <Flex>
      <InputField
        value={getAddressValue(values.location)}
        name='location'
        locationInput={true}
        isRequired={true}
        placeholder="Search for an address..."
      />
      {suggestions.length > 0 && (
        <Box
          position="absolute"
          bg="white"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          maxHeight="200px"
          overflowY="auto"
          zIndex={1000}
          mt={10}
        >
          <List spacing={1}>
            {suggestions.map((suggestion: any, index) => (
              <ListItem
                key={index}
                padding={2}
                cursor="pointer"
                _hover={{ backgroundColor: 'gray.100' }}
                onClick={() => handleSelectSuggestion(suggestion)}
              >
                {suggestion.label}
              </ListItem>
            ))}
          </List>
        </Box>
    )}
    </Flex>
  )
}