import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TenantType } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useAddHighlight = () => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["updateHighlight"],
    mutationFn: (image: File) => {
      const fd = new FormData();
      fd.append("highlight", image, image.name);
      return api.post("/tenant/highlights", { body: fd }, true);
    },
    onSuccess: (res: any) => {
      qC.setQueryData(["authenticated-user"], (oldData: TenantType) => {
        if (oldData) {
          return {
            ...oldData,
            tenantData: {
              ...oldData.tenantData,
              photoHighlights: oldData.tenantData?.photoHighlights
                ? oldData.tenantData?.photoHighlights.concat([res.data])
                : [res.data],
            },
          };
        }
        return oldData;
      });
      useNotifications.getState().addNotification({
        type: "success",
        title: "Success",
        message: `Successfully updated highlights`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: "error",
        title: "Failed to update highlights",
        message: error.message ? error.message : "Try again",
      });
    },
  });
  return mutation;
};
