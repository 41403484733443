import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ListingType } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useEditListing = (id: string, propertyId: string) => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["updateListing", id],
    mutationFn: (listing: Partial<ListingType>) => {
      return api.patch(
        `/listing/${id}`,
        { body: JSON.stringify(listing) },
        true,
        "json"
      );
    },
    onSuccess: (res: any, data: Partial<ListingType>) => {
      qC.setQueryData(["myListings", propertyId], (oldData: ListingType[]) => {
        if (oldData) {
          return oldData.map((oldListing: ListingType) =>
            oldListing._id === id ? { ...oldListing, ...data } : oldListing
          );
        }
        return oldData;
      });
      useNotifications.getState().addNotification({
        type: "success",
        title: "Success",
        message: `Successfully updated property`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: "error",
        title: "Failed to edit property",
        message: error.message ? error.message : "Try again",
      });
    },
  });
  return mutation;
};
