import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PropertyType, RoommateDetailType } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

type Arg = {
  roommatedetail: RoommateDetailType;
  index: number;
};

export const useEditRoommate = (propertyId: string) => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["updateRoommate"],
    mutationFn: ({ roommatedetail, index }: Arg) => {
      const fd = new FormData();

      if (roommatedetail.pfp) {
        if (typeof roommatedetail.pfp !== "string") {
          fd.append("pfp", roommatedetail.pfp, roommatedetail.pfp.name);
          delete roommatedetail["pfp"];
        }
      }

      for (const key of Object.keys(roommatedetail)) {
        const val = roommatedetail[key as keyof typeof roommatedetail];
        fd.append(key, val as Blob);
      }

      fd.append("index", index.toString() as unknown as Blob);

      return api.patch(`/property/roommate/${propertyId}`, { body: fd }, true);
    },
    onSuccess: (res: any, { index }) => {
      qC.setQueryData(["property", propertyId], (oldData: PropertyType) => {
        if (oldData) {
          return {
            ...oldData,
            roommateDetails: oldData.roommateDetails
              ? oldData.roommateDetails.map((_: any, i: number) =>
                  i === index ? res.data : _
                )
              : [res.data],
          };
        }
        return oldData;
      });
      useNotifications.getState().addNotification({
        type: "success",
        title: "Success",
        message: `Successfully updated room photos`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: "error",
        title: "Failed to update room photos",
        message: error.message ? error.message : "Try again",
      });
    },
  });
  return mutation;
};
