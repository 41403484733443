import { Flex } from "@chakra-ui/react";
import { InputField } from "components/molecules";
import { FormikValues, useFormikContext } from "formik";

export const EditRoomsize = () => {
  const { values }: FormikValues = useFormikContext();

  return (
    <Flex>
      <InputField
        name="roomSize"
        value={values.roomSize}
        isRequired={true}
        label="Room Size"
        type="number"
        step="any"
        placeholder="12.5"
      />
    </Flex>
  );
};
