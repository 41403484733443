import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TenantType } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useEditTenant = () => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ['updateTenant'],
    mutationFn: (tenant: Partial<TenantType>) => {
      let obj: any = {...tenant};
      delete obj["moveIn"];
      delete obj["moveOut"];
      return api.put('/tenant', {body: JSON.stringify(obj)}, true, 'json');
    },
    onSuccess: (res: any, updatedTenant) => {
      qC.setQueryData(
        ['authenticated-user'],
        (oldData: TenantType, ) => {
          if (oldData){
            const updatedTenantData = updatedTenant.tenantData;
            delete updatedTenant['tenantData']
            return {...oldData, ...updatedTenant, tenantData: {...oldData.tenantData, ...updatedTenantData}}
          }
          return oldData
        }
      )      
      useNotifications.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: `Successfully updated profile`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to edit profile',
        message: error.message ? error.message : 'Try again',
      });
    }
  })
  return mutation;
}