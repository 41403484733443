import { dateFormatter, getAddressValue, StatusEnum } from "utils";
import { ApplicationsTabPanelLayout } from "./ApplicationTabPanelLayout";
import { Spinner } from "components/atoms";
import { useGetShortlistListing, useGetShortlistTenants } from "lib";
import { ListingType, PropertyType, TenantType } from "types";
import { Fragment, useState } from "react";
import { Divider, Image } from "@chakra-ui/react";
import {
  ListingPostModal,
  SecondaryButton,
  TenantPostModal,
} from "components/molecules";
import DefaultImg from "assets/images/photoHighlights-2c716dbb164304be.png";
import ViewIcon from "assets/images/ViewIcon.svg";
import { ApplicationRow } from "./ApplicationRow";

const ApplicationRowDivider = () => (
  <Divider mx="1" display={"inline"} orientation="vertical" />
);

const TenantShortlistApplicationRow = ({
  property,
  listing,
  viewApartment,
}: {
  property: PropertyType;
  listing: ListingType;
  viewApartment: (l: ListingType) => void;
}) => {
  if (property && listing) {
    const { roomPhotos, location, roomSize } = property;
    const { rent, moveInDate, moveOutDate } = listing;

    return (
      <>
        <ApplicationRow
          img={
            roomPhotos
              ? roomPhotos.length > 0
                ? `${process.env.REACT_APP_BASE_URL}/${roomPhotos[0]}`
                : DefaultImg
              : DefaultImg
          }
          main={getAddressValue(location)}
          subMain={
            <>
              {roomSize} m<sup>2</sup>
              <ApplicationRowDivider />
              {rent}$
              <ApplicationRowDivider />
              {dateFormatter(moveInDate)} -{dateFormatter(moveOutDate)}
            </>
          }
          buttons={
            <>
              <SecondaryButton
                onClick={() => viewApartment(listing)}
                mr={[0, 2]}
                mb={[2, 0]}
                padding={4}
                color="black"
              >
                <Image src={ViewIcon} mr={2} /> View Apartment
              </SecondaryButton>
              {/* <PrimaryButton padding={4}>
              <Image src={ChatIcon} mr={2} /> Chat
            </PrimaryButton> */}
            </>
          }
        />
      </>
    );
  }
  return <></>;
};

const RenterShortlistApplicationRow = ({
  application,
  viewTenant,
}: {
  viewTenant: (t: TenantType) => void;
  application: TenantType;
}) => {
  const { name, age, tenantData, profilepic } = application;
  const { moveInDate, moveOutDate, budget } = tenantData;
  return (
    <ApplicationRow
      img={
        profilepic
          ? `${process.env.REACT_APP_BASE_URL}/${profilepic}`
          : DefaultImg
      }
      main={name}
      subMain={
        <>
          {age}
          <ApplicationRowDivider />
          {budget}$
          <ApplicationRowDivider />
          {dateFormatter(moveInDate)} -{dateFormatter(moveOutDate)}
        </>
      }
      buttons={
        <>
          <SecondaryButton
            mr={[0, 2]}
            mb={[2, 0]}
            padding={4}
            onClick={() => viewTenant(application)}
            color="black"
          >
            <Image src={ViewIcon} mr={2} /> View Tenant
          </SecondaryButton>
          {/* <PrimaryButton padding={4}>
          <Image src={ChatIcon} mr={2} /> Chat
        </PrimaryButton> */}
        </>
      }
    />
  );
};

const TenantShortlistedApplications = () => {
  let { isFetching, data: applications } = useGetShortlistListing();
  const [modal, setModal] = useState(false);
  const [modalListing, setModalListing] = useState<ListingType | null>(null);

  const openModal = (listing: ListingType) => {
    setModalListing(listing);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setModalListing(null);
  };

  return (
    <>
      {isFetching ? <Spinner /> : <></>}
      {applications ? (
        applications.map(
          (
            {
              listing,
              property,
            }: { listing: ListingType; property: PropertyType },
            i: number
          ) => (
            <Fragment key={i}>
              <TenantShortlistApplicationRow
                viewApartment={openModal}
                property={property}
                listing={listing}
              />
              {i === (applications ? applications?.length * 2 - 1 : -1) ? (
                <></>
              ) : (
                <Divider my={3} orientation="horizontal" />
              )}
            </Fragment>
          )
        )
      ) : (
        <></>
      )}
      <ListingPostModal
        open={modal}
        setClose={closeModal}
        listing={modalListing}
      />
    </>
  );
};

const RenterShortlistedApplications = () => {
  let { isFetching, data: applications } = useGetShortlistTenants();
  const [modal, setModal] = useState(false);
  const [modalListing, setModalListing] = useState<TenantType | null>(null);

  const openModal = (listing: TenantType) => {
    setModalListing(listing);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setModalListing(null);
  };

  return (
    <>
      {isFetching ? <Spinner /> : <></>}
      {applications ? (
        applications.map((application: TenantType, i: number) => (
          <Fragment key={i}>
            <RenterShortlistApplicationRow
              viewTenant={openModal}
              application={application}
            />
            {i === (applications ? applications?.length - 1 : -1) ? (
              <></>
            ) : (
              <Divider my={3} orientation="horizontal" />
            )}
          </Fragment>
        ))
      ) : (
        <></>
      )}
      <TenantPostModal
        open={modal}
        setClose={closeModal}
        tenant={modalListing}
      />
    </>
  );
};

export const ShortlistedApplications = ({ status }: { status: number }) => {
  return (
    <ApplicationsTabPanelLayout>
      {status === StatusEnum.Tenant ? <TenantShortlistedApplications /> : <></>}
      {status === StatusEnum.Renter ? <RenterShortlistedApplications /> : <></>}
    </ApplicationsTabPanelLayout>
  );
};
