import { useQuery } from "@tanstack/react-query"
import { api } from "lib/api-client"

export const useGetChats = () => {
  let query = useQuery<{_id: string, name: string, profilepic?: string}[]>({
    queryKey: ['chats'],
    queryFn: async () => {
      const r = await api.get(`/chat`, {}, true)
      return r.data;
    },
    refetchOnWindowFocus: true,
    // data will be refetched on window focus after 30 mins
    staleTime: 30 * 60 * 1000
  })
  return query;
}