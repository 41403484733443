import { dateFormatter, getAddressValue, StatusEnum } from "utils";
import { ApplicationsTabPanelLayout } from "./ApplicationTabPanelLayout";
import { Spinner } from "components/atoms";
import { ListingType, PropertyType, TenantType } from "types";
import { Fragment, useState } from "react";
import { Divider, Image } from "@chakra-ui/react";
import {
  ListingPostModal,
  SecondaryButton,
  TenantPostModal,
} from "components/molecules";
import DefaultImg from "assets/images/photoHighlights-2c716dbb164304be.png";
import ViewIcon from "assets/images/ViewIcon.svg";
import { useGetPendingListing } from "lib";
import { useGetPendingTenants } from "lib";
import { ApplicationRow } from "./ApplicationRow";
import PendingIcon from "assets/images/PendingIcon.svg";

const TenantPendingApplicationRow = ({
  listing,
  property,
  viewApartment,
}: {
  viewApartment: (l: ListingType) => void;
  property: PropertyType;
  listing: ListingType;
}) => {
  let { roomPhotos, location, roomSize } = property;
  let { rent, moveInDate, moveOutDate } = listing;
  return (
    <ApplicationRow
      img={
        roomPhotos
          ? roomPhotos.length > 0
            ? `${process.env.REACT_APP_BASE_URL}/${roomPhotos[0]}`
            : DefaultImg
          : DefaultImg
      }
      main={getAddressValue(location)}
      subMain={
        <>
          {roomSize} m<sup>2</sup>
          <Divider mx="1" display={"inline"} orientation="vertical" />
          {rent}$
          <Divider display={"inline"} mx="1" orientation="vertical" />
          {dateFormatter(moveInDate)} - {dateFormatter(moveOutDate)}
        </>
      }
      buttons={
        <>
          <SecondaryButton
            mr={[0, 2]}
            mb={[2, 0]}
            onClick={() => viewApartment(listing)}
            padding={4}
            color="black"
          >
            <Image src={ViewIcon} mr={2} /> View Apartment
          </SecondaryButton>
          <SecondaryButton padding={4}>
            <Image src={PendingIcon} mr={2} /> Pending
          </SecondaryButton>
        </>
      }
    />
  );
};

const RenterPendingApplicationRow = ({
  application,
  viewTenant,
}: {
  viewTenant: (t: TenantType) => void;
  application: TenantType;
}) => {
  const { name, age, tenantData, profilepic } = application;
  const { moveInDate, moveOutDate, budget } = tenantData;
  return (
    <ApplicationRow
      img={
        profilepic
          ? `${process.env.REACT_APP_BASE_URL}/${profilepic}`
          : DefaultImg
      }
      main={name}
      subMain={
        <>
          {age}
          <Divider mx="1" display={"inline"} orientation="vertical" />
          {budget}$
          <Divider mx="1" display={"inline"} orientation="vertical" />
          {dateFormatter(moveInDate)} -{dateFormatter(moveOutDate)}
        </>
      }
      buttons={
        <>
          <SecondaryButton
            mr={[0, 2]}
            mb={[2, 0]}
            padding={4}
            onClick={() => viewTenant(application)}
            color="black"
          >
            <Image src={ViewIcon} mr={2} /> View Tenant
          </SecondaryButton>
          <SecondaryButton padding={4}>
            <Image src={PendingIcon} mr={2} /> Pending
          </SecondaryButton>
        </>
      }
    />
  );
};

const TenantPendingApplications = () => {
  let { isFetching, data: applications } = useGetPendingListing();
  const [modal, setModal] = useState(false);
  const [modalListing, setModalListing] = useState<ListingType | null>(null);

  const openModal = (listing: ListingType) => {
    setModalListing(listing);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setModalListing(null);
  };

  return (
    <>
      {isFetching ? <Spinner /> : <></>}
      {applications ? (
        applications.map(
          (
            {
              listing,
              property,
            }: {
              listing: ListingType;
              property: PropertyType;
            },
            i: number
          ) => (
            <Fragment key={i}>
              <TenantPendingApplicationRow
                listing={listing}
                property={property}
                viewApartment={openModal}
              />
              {i === (applications ? applications?.length - 1 : -1) ? (
                <></>
              ) : (
                <Divider my={3} orientation="horizontal" />
              )}
            </Fragment>
          )
        )
      ) : (
        <></>
      )}
      <ListingPostModal
        open={modal}
        setClose={closeModal}
        listing={modalListing}
      />
    </>
  );
};

const RenterPendingApplications = () => {
  let { isFetching, data: applications } = useGetPendingTenants();
  const [modalListing, setModalListing] = useState<TenantType | null>(null);
  const [modal, setModal] = useState(false);

  const openModal = (listing: TenantType) => {
    setModalListing(listing);
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
    setModalListing(null);
  };

  return (
    <>
      {isFetching ? <Spinner /> : <></>}
      {applications ? (
        applications.map((application: TenantType, i: number) => (
          <Fragment key={i}>
            <RenterPendingApplicationRow
              viewTenant={openModal}
              application={application}
            />
            {i === (applications ? applications?.length - 1 : -1) ? (
              <></>
            ) : (
              <Divider my={3} orientation="horizontal" />
            )}
          </Fragment>
        ))
      ) : (
        <></>
      )}
      <TenantPostModal
        open={modal}
        setClose={closeModal}
        tenant={modalListing}
      />
    </>
  );
};

export const PendingApplications = ({ status }: { status: number }) => {
  return (
    <ApplicationsTabPanelLayout>
      {status === StatusEnum.Tenant ? <TenantPendingApplications /> : <></>}
      {status === StatusEnum.Renter ? <RenterPendingApplications /> : <></>}
    </ApplicationsTabPanelLayout>
  );
};
