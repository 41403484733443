import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Grid,
  Flex,
  GridItem,
  Box,
  Image,
  IconButton,
  Icon,
  Divider,
  Text,
  VStack,
  Avatar,
} from "@chakra-ui/react";
import {
  Body1,
  Body4,
  PrimaryButton,
  SelectedItems,
  Title1,
} from "components/molecules";
import {
  useAddRoomPhoto,
  useDeleteRoommate,
  useGetProperty,
  useRemoveRoomPhoto,
} from "lib";
import { ChangeEvent, useRef, useState } from "react";
import Roommate from "assets/images/RoommateIcon.svg";
import RoomSize from "assets/images/RoomSizeIcon.svg";
import { useParams } from "react-router-dom";
import { getAddressValue, ROOM_PHOTOS_MAX_IMAGES } from "utils";
import SchoolIcon from "assets/images/SchoolIcon.svg";
import BusIcon from "assets/images/BusIcon.svg";
import BuildingIcon from "assets/images/BuildingIcon.svg";
import { language_mappings } from "utils/data";
import Location from "assets/images/LocationIcon.svg";
import { MyListings } from "./MyListings";
import {
  Delete,
  DeleteIcon,
  EditIcon,
  LucideDelete,
  Trash2,
} from "lucide-react";
import { DetailBar } from "../Dashboard/DetailBar";
import { EditModal } from "./EditModals";
import { EditRoommateDetailModal } from "./RoommateDetail";
import { RoommateDetailType } from "types";

export const MyProperty = () => {
  const { propertyId } = useParams();
  const { data: property, isFetching } = useGetProperty(propertyId ?? "");
  const roomPhotosRef = useRef<HTMLInputElement | null>(null);

  const [editModal, setEditModal] = useState<boolean>(false);
  const [editRoommateDetailModal, setEditRoommateDetailModal] =
    useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");
  const [editRoommateIndex, setEditRoomateIndex] = useState<number | null>(
    null
  );

  const uploadMutation = useAddRoomPhoto(propertyId ?? "");
  const removeMutation = useRemoveRoomPhoto(propertyId ?? "");
  const removeRoommateMutation = useDeleteRoommate(propertyId ?? "");

  if (isFetching) {
    return <></>;
  }

  if (!property) {
    return <></>;
  }

  const {
    roomPhotos,
    title,
    roomSize,
    location,
    roommates,
    roommateDetails,
    extraDescription,
    extraDetails,
    uni_min,
    bus_min,
    cc_min,
    _id,
  } = property;

  const handleOpen = (id: string) => {
    setEditId(id);
    setEditModal(true);
  };

  const handleClose = () => {
    setEditModal(false);
  };

  const fileChangeRoomPhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (files.length > 0) {
        uploadMutation.mutate(files[0]);
      }
    }
  };

  const fileRemoveHighlight = (index: number) => {
    removeMutation.mutate(index);
  };

  const removeRoommate = (index: number) => {
    removeRoommateMutation.mutate(index);
  };

  const editRoommate = (index: number) => {
    setEditRoomateIndex(index);
    setEditRoommateDetailModal(true);
  };

  return (
    <Flex
      flexDir={"column"}
      border={"1px solid rgba(216, 220, 224, 1)"}
      borderRadius={"8px"}
      padding={"20px 50px"}
      alignItems={"center"}
      width={"100%"}
      height={"100%"}
      overflow={"scroll"}
      className="hide-scrollbar"
    >
      <VStack
        padding={6}
        width={"100%"}
        maxH={"100%"}
        className="apartment-details"
        overflowX={"hidden"}
        overflowY={["scroll"]}
      >
        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(5, 1fr)`]}
          gap={5}
        >
          <GridItem colSpan={[1, 5]} display={"flex"} alignItems={"center"}>
            <Text marginRight={"5px"} textStyle="medium" fontSize="2xl">
              {title}
            </Text>
            <EditIcon
              size={"18px"}
              cursor={"pointer"}
              onClick={() => handleOpen("title")}
            />
          </GridItem>
          <input
            ref={roomPhotosRef}
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={fileChangeRoomPhoto}
          />
          {Array.from({ length: ROOM_PHOTOS_MAX_IMAGES }).map((_, index) => (
            <GridItem key={index} position="relative">
              {roomPhotos?.[index] ? (
                <Box position="relative">
                  <Image
                    src={
                      process.env.REACT_APP_BASE_URL + "/" + roomPhotos[index]
                    }
                    alt={`Thumbnail ${index + 1}`}
                    borderRadius="8px"
                    boxSize="150px"
                    objectFit="cover"
                  />
                  <IconButton
                    aria-label="Remove Image"
                    icon={<CloseIcon />}
                    size="xs"
                    position="absolute"
                    top="4px"
                    right="4px"
                    backgroundColor="rgba(0,0,0,0.6)"
                    color="white"
                    _hover={{ backgroundColor: "red.500" }}
                    onClick={() => fileRemoveHighlight(index)}
                  />
                </Box>
              ) : (
                <Box
                  borderRadius="8px"
                  boxSize="150px"
                  backgroundColor="gray.200"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() => roomPhotosRef.current?.click()}
                >
                  <Icon as={AddIcon} boxSize={6} color="gray.500" />
                </Box>
              )}
            </GridItem>
          ))}
        </Grid>

        <Divider orientation="horizontal" margin={3} />

        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
          gap={5}
        >
          <DetailBar
            openModal={() => handleOpen("roomsize")}
            icon={RoomSize}
            name="Room Size"
            value={
              <>
                {roomSize} m<sup>2</sup>
              </>
            }
          />
          <DetailBar
            openModal={() => handleOpen("roommates")}
            icon={Roommate}
            name="Roommates"
            value={<>{roommates}</>}
          />
          <DetailBar
            openModal={() => handleOpen("location")}
            icon={Location}
            name="Location"
            value={<>{getAddressValue(location)}</>}
          />
        </Grid>

        <Divider orientation="horizontal" padding={3} />

        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(3, 1fr)`]}
          gap={2}
        >
          <GridItem
            colSpan={[1, 3]}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Title1 display="inline">Roommates</Title1>
            <PrimaryButton onClick={() => setEditRoommateDetailModal(true)}>
              + Add
            </PrimaryButton>
          </GridItem>
          {roommateDetails.map(
            ({ name, age, gender, language, pfp }: any, i: number) => (
              <GridItem
                key={`${name}-${i}`}
                backgroundColor={"rgba(0,0,0,0.05)"}
                borderRadius={"8px"}
                padding={3}
                colSpan={1}
              >
                <Flex>
                  <Avatar
                    marginRight={"10px"}
                    height={"50px"}
                    width={"50px"}
                    src={`${process.env.REACT_APP_BASE_URL}/${pfp}`}
                  />
                  <VStack alignItems={"flex-start"} gap={0}>
                    <Body1>{name}</Body1>
                    {age ? <Body4>Age: {age}</Body4> : <></>}
                    {language ? (
                      <Body4>
                        Speaks:{" "}
                        {language
                          .split(",")
                          .map((l: any) => language_mappings[l])
                          .join(",")}
                      </Body4>
                    ) : (
                      <></>
                    )}
                  </VStack>
                  <Flex
                    marginLeft={"auto"}
                    cursor={"pointer"}
                    height={"fit-content"}
                    gap={2}
                  >
                    <EditIcon onClick={() => editRoommate(i)} size={"18px"} />
                    <Trash2 onClick={() => removeRoommate(i)} size={"18px"} />
                  </Flex>
                </Flex>
              </GridItem>
            )
          )}
        </Grid>

        <Divider orientation="horizontal" padding={3} />

        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(3, 1fr)`]}
          gap={5}
        >
          <GridItem colSpan={[1, 3]} display={"flex"} alignItems={"center"}>
            <Title1 marginRight="5px">Good to know</Title1>
            <EditIcon
              size={"18px"}
              cursor={"pointer"}
              onClick={() => handleOpen("amenities")}
            />
          </GridItem>
          <SelectedItems items={extraDetails} />
        </Grid>

        <Divider orientation="horizontal" padding={3} />

        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
          gap={5}
        >
          <GridItem colSpan={3} display={"flex"} alignItems={"center"}>
            <Title1 marginRight="5px">Other details</Title1>
            <EditIcon
              size={"18px"}
              cursor={"pointer"}
              onClick={() => handleOpen("otherDetails")}
            />
          </GridItem>
          <GridItem colSpan={3}>
            <Text fontSize="sm">{extraDescription ?? "None"}</Text>
          </GridItem>
        </Grid>
        <Divider orientation="horizontal" padding={3} />
        <Grid
          width={"100%"}
          templateRows="repeat(1, 1fr)"
          templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
          gap={5}
        >
          <GridItem colSpan={[1, 2]}>
            <Title1>What's nearby?</Title1>
          </GridItem>
          <DetailBar
            openModal={() => handleOpen("nearby")}
            icon={SchoolIcon}
            name="University"
            value={<>{uni_min ? `${uni_min} mins away` : `Not known`}</>}
          />
          <DetailBar
            openModal={() => handleOpen("nearby")}
            icon={BusIcon}
            name="Bus stop"
            value={<>{bus_min ? `${bus_min} mins away` : `Not known`}</>}
          />
          <DetailBar
            openModal={() => handleOpen("nearby")}
            icon={BuildingIcon}
            name="City center"
            value={<>{cc_min ? `${cc_min} mins away` : `Not known`}</>}
          />
        </Grid>
        <Divider orientation="horizontal" my={5} />
        <MyListings />
      </VStack>

      {editModal && (
        <EditModal
          property={property}
          id={editId}
          isOpen={editModal}
          handleClose={handleClose}
        />
      )}

      {editRoommateDetailModal && (
        <EditRoommateDetailModal
          property={property}
          roommateIndex={editRoommateIndex}
          handleClose={() => {
            setEditRoommateDetailModal(false);
            setEditRoomateIndex(null);
          }}
        />
      )}
    </Flex>
  );
};
