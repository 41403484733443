import { Flex, Text } from "@chakra-ui/react";
import { PrimaryButton } from "components/molecules";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <Flex justifyContent={"space-between"} width={"100%"} alignItems={"center"}>
      <Text textStyle={"medium"} fontSize={"xl"}>
        My Properties
      </Text>
      <Link to="/app/create/property">
        <PrimaryButton> + Create </PrimaryButton>
      </Link>
    </Flex>
  );
};
