import * as React from "react";
import { Flex } from "@chakra-ui/react";

export type LayoutVariant = "small" | "regular" | "large";

interface LayoutProps {
  children: React.ReactNode;
  variant?: LayoutVariant;
  height?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, height }) => {
  return (
    <Flex w="100%" direction="column">
      <Flex
        w="100vw"
        as="main"
        direction="column"
        minHeight={height ? height : "100vh"}
        flexWrap="wrap"
      >
        {children}
      </Flex>
    </Flex>
  );
};
