import { Avatar, Flex, VStack } from "@chakra-ui/react";
import { Body1, Body4 } from "components/molecules";
import { PropertyType } from "types";
import { getAddressValue } from "utils";
import { PropertyOptions } from "./propertyOptions";
import { Link } from "react-router-dom";

export const PropertyRow = ({ property }: { property: PropertyType }) => {
  const { title, roomPhotos, location, _id } = property;

  return (
    <Flex
      flexDir={["column", "row"]}
      mb={5}
      justifyContent={["flex-start", "space-between"]}
    >
      <Flex>
        <Link to={"/app/property/" + _id}>
          <Flex cursor={"pointer"}>
            <Avatar
              marginRight={"10px"}
              height={"50px"}
              width={"50px"}
              src={`${process.env.REACT_APP_BASE_URL}/${roomPhotos[0]}`}
            />
            <VStack alignItems={"flex-start"} gap={0}>
              <Body1>{title}</Body1>
              <Body4>{getAddressValue(location)}</Body4>
            </VStack>
          </Flex>
        </Link>
      </Flex>
      <Flex flexDir={["column", "row"]}>
        <PropertyOptions property={property} />
      </Flex>
    </Flex>
  );
};
