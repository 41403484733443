import { Divider, Flex } from "@chakra-ui/react"
import { Header } from "./header"
import { Body } from "./body"

export const MyProperties = () => {
	
	return(
		<Flex flexDir={'column'}>
			<Header />
			<Divider orientation="horizontal" my={5}/>
			<Body />
		</Flex>
	)
}