import { PropertyType } from "types"
import { useState } from "react"
import { ListingPostModal, PrimaryButton } from "components/molecules"
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import { AddIcon, DeleteIcon, EditIcon, ExternalLinkIcon, HamburgerIcon, RepeatIcon } from "@chakra-ui/icons"

export const PropertyOptions = ({property}: {
  property: PropertyType
}) => {
  const { active } = property

  const [open, setOpen] = useState(false)


  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          aria-label='Options'
          icon={<HamburgerIcon />}
          variant='outline'
        />
        <MenuList>
          <MenuItem disabled={!active} icon={<AddIcon />}>
            View Property
          </MenuItem>
          <MenuItem icon={<EditIcon />}>
            Edit Property
          </MenuItem>
          <MenuItem icon={<DeleteIcon />}>
            View Listings
          </MenuItem>
        </MenuList>
      </Menu>
      {/* <ListingPostModal open={open} setClose={() => setOpen(false)}
        listing={modalListing}
        /> */}
    </>
  )
}
