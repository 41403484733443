import { Spacer } from "@chakra-ui/react";
import { InputDateField, RadioField } from "components/molecules";
import { Formik, FormikValues, useFormikContext } from "formik";
import { useEffect } from "react";

export const EditMoveInMoveOutDate = () => {
  const { values, setFieldValue }: FormikValues = useFormikContext();

  useEffect(() => {
    setFieldValue("moveIn", values.moveInDate ? "later" : "now");
    setFieldValue("moveOut", values.moveOutDate ? "later" : "now");
  }, []);

  return (
    <>
      <RadioField
        isRequired={true}
        label="Preferred move-in date?"
        options={{ now: "As soon as possible", later: "Select Date" }}
        value={values.moveIn}
        name="moveIn"
        onChange={(e) => (e === "now" ? setFieldValue("moveInDate", "") : "")}
      />
      {values.moveIn === "later" && (
        <InputDateField
          isRequired={true}
          isDisabled={values.moveIn === "now"}
          label={"Date"}
          minDate={new Date()}
          value={values.moveInDate}
          name="moveInDate"
        />
      )}
      <Spacer my={5} flex={0} />
      <RadioField
        isRequired={true}
        label="For how long is the apartment available?"
        options={{ now: "As long as possible", later: "Select date" }}
        value={values.moveOut}
        name="moveOut"
        onChange={(e) => (e === "now" ? setFieldValue("moveOutDate", "") : "")}
      />
      {values.moveOut === "later" && (
        <InputDateField
          isRequired={true}
          minDate={values.moveInDate ? new Date(values.moveInDate) : new Date()}
          isDisabled={values.moveOut === "now"}
          label={"Date"}
          name="moveOutDate"
          value={values.moveOutDate}
        />
      )}
    </>
  );
};
