import { InputField } from "components/molecules";
import { FormikValues, useFormikContext } from "formik";

export const EditTitle = () => {
  const { values }: FormikValues = useFormikContext();

  return (
    <InputField
      name="title"
      type="text"
      value={values.title}
      propertyTitle={true}
      isRequired={true}
      label="Title"
      placeholder="Enter title or click generate..."
    />
  );
};
