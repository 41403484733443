import { Fragment, useState } from "react";
import { Divider } from "@chakra-ui/react";
import { ListingType, PropertyType, TenantType } from "types";
import { Spinner } from "components/atoms";
import { useGetMatchedTenants } from "lib";
import { RenterAcceptedApplicationRow } from "./AcceptedApplicationRow";
import { TenantPostModal } from "components/molecules";

export const RenterAcceptedApplications = () => {
  let { isFetching, data: applications} = useGetMatchedTenants()
  const [modal, setModal] = useState(false)
  const [modalTenant, setModalTenant] = useState<TenantType|null>(null)

  const openModal = (tenant: TenantType) => {
    setModalTenant(tenant)
    setModal(true)
  }
  const closeModal = () => {
    setModal(false)
    setModalTenant(null)
  }

  return(
    <>
      {isFetching ? (
        <Spinner />
      ) : (<></>)}
      {applications ? applications.map(({
        tenant,
        listing,
        property
      }: {
        tenant: TenantType, 
        listing: ListingType, 
        property: PropertyType
      }, i: number) => (
        <Fragment key={i}>
          <RenterAcceptedApplicationRow viewTenant={openModal} tenant={tenant} property={property} listing={listing} />
          {(i === (applications ? applications?.length - 1 : -1) ? <></> : <Divider my={3} orientation="horizontal" />)}
        </Fragment>
      )) : (<></>)}
      <TenantPostModal open={modal} setClose={closeModal}
        tenant={modalTenant}
      />
    </>
  );
}