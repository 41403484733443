import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PropertyType, RoommateDetailType } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useDeleteRoommate = (propertyId: string) => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["deleteRoommate"],
    mutationFn: (index: number) => {
      return api.delete(
        `/property/roommate/${propertyId}`,
        { body: JSON.stringify({ index }) },
        true,
        "json"
      );
    },
    onSuccess: (res: any, index: number) => {
      qC.setQueryData(["property", propertyId], (oldData: PropertyType) => {
        if (oldData) {
          return {
            ...oldData,
            roommateDetails: oldData.roommateDetails
              ? oldData.roommateDetails.filter((_, i) => i !== index)
              : [],
          };
        }
        return oldData;
      });
      useNotifications.getState().addNotification({
        type: "success",
        title: "Success",
        message: `Successfully removed roommate`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: "error",
        title: "Failed to remove roommate",
        message: error.message ? error.message : "Try again",
      });
    },
  });
  return mutation;
};
