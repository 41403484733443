import { Flex, Text } from "@chakra-ui/react"
import { PrimaryButton } from "components/molecules"

export const Header = () => {
  return(
    <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
      <Text textStyle={'medium'} fontSize={'xl'}>Listings</Text>
      <PrimaryButton isDisabled={true}> + Create </PrimaryButton>
    </Flex>
  )
}