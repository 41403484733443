import { Avatar, Flex } from "@chakra-ui/react"
import { useGetMyProperties } from "lib"
import { PropertyRow } from "./propertyRow"

export const Body = () => {
  const { data } = useGetMyProperties()
  
  return(
    <>
      {data?.map(property => (
        <PropertyRow 
          property={property} 
          key={property._id} 
        />  
      ))}
    </>
  )
}