import { InputField } from "components/molecules"
import { FormikValues, useFormikContext } from "formik"

export const EditPhone = () => {
  const { values }: FormikValues = useFormikContext()
  
  return(
    <InputField
      name='tenantData.phoneNumber'
      value={values.tenantData.phoneNumber}
      isRequired={true}
      label='Phone number'
      placeholder='+49 151 1234 1234'
      // locationInput={true}
    />
  
  )
}