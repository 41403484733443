import { IEnumType } from "types";

export const enumToObject = (enumObj: IEnumType): { [s: number]: string } => {
  let obj: { [s: number]: string } = {};
  for (let key in enumObj) {
    if (Number(key) > 0) {
      obj[parseInt(key)] = enumObj[key];
    }
  }
  console.log(obj);
  return obj;
};

export const splt = (s: string, ind: number, sep = ".") => s.split(sep)[ind];

export const dateFormatter = (date: any, defaultStr: string = "N/A") => {
  if (date) {
    let a = new Date(date);
    return `${a.getDate()}.${a.getMonth() + 1}.${a.getFullYear()}`;
  } else {
    return defaultStr;
  }
};

export const getDurationOfStay = (startDate: any, endDate: any) => {
  if (endDate) {
    return `${Math.abs(
      Math.round(
        ((startDate ? new Date(startDate) : new Date()).getTime() -
          new Date(endDate).getTime()) /
          (1000 * 60 * 60 * 24 * 7 * 4)
      )
    )} month(s)`;
  } else {
    return `Unlimited`;
  }
};

export const getAddressValue = (val: any) => {
  if (typeof val === "string") {
    try {
      return getAddressString(JSON.parse(val));
    } catch (e: any) {}
  }
  return val;
};

export const getAddressObj = (val: any, key: any) => {
  if (typeof val === "string") {
    try {
      const a = JSON.parse(val);
      return a.address ? a.address[key] : "";
    } catch (e: any) {
      return "";
    }
  }
  return val;
};

export const getAddressString = (item: any) => {
  if (item) {
    const road = item.address.road || "";
    const houseNumber = item.address.house_number || "";
    const postcode = item.address.postcode || "";
    const city =
      item.address.city || item.address.town || item.address.village || "";

    return `${road ? road + " " : ""}${houseNumber ? houseNumber + ", " : ""}${
      postcode ? postcode + ", " : ""
    }${city}`;
  }
};
