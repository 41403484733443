import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { PrimaryButton, SecondaryButton } from "components/molecules";
import { Form, Formik } from "formik";
import { ListingType, PropertyType } from "types";
import { useEditListing, useEditTenant } from "lib";
import {
  EditAge,
  EditContract,
  EditMoveInMoveOutDate,
  EditRent,
  EditSecurityDeposit,
} from "./EditForms";
import { EditGender } from "components/organisms/Profile/common";

type EditModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  id: string;
  listing: ListingType;
};

const editTypes: { [key: string]: any } = {
  rent: {
    comp: EditRent,
    header: "Edit Rent (EUR)",
    fieldsToSubmit: (values: any) => ({
      rent: values.rent,
    }),
  },
  securityDeposit: {
    comp: EditSecurityDeposit,
    header: "Edit Security Deposit (EUR)",
    fieldsToSubmit: (values: any) => ({
      securityDeposit: values.securityDeposit,
    }),
  },
  contract: {
    comp: EditContract,
    header: "Edit Contract",
    fieldsToSubmit: (values: any) => ({
      contract: values.contract,
    }),
  },
  age: {
    comp: EditAge,
    header: "Edit Age",
    fieldsToSubmit: (values: any) => ({
      age: values.age,
    }),
  },
  gender: {
    comp: EditGender,
    header: "Edit Gender",
    fieldsToSubmit: (values: any) => ({
      gender: values.gender,
    }),
  },
  moveinout: {
    comp: EditMoveInMoveOutDate,
    header: "Edit Move In & Out Details",
    fieldsToSubmit: (values: any) => ({
      moveInDate: values.moveInDate ?? "",
      moveOutDate: values.moveOutDate ?? "",
    }),
  },
};

export const EditModal = ({
  isOpen,
  handleClose,
  id,
  listing,
}: EditModalProps) => {
  const { comp: EditFields, header, fieldsToSubmit } = editTypes[id];

  const mutation = useEditListing(listing._id, listing.property);

  return (
    <Modal isOpen={isOpen} isCentered onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <Formik
            initialValues={listing}
            validate={() => ({})}
            onSubmit={async (values) => {
              try {
                await mutation.mutateAsync(fieldsToSubmit(values), {
                  onSuccess: () => {
                    handleClose();
                  },
                  onError: (e: any) => {},
                });
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <Form>
              <ModalHeader>{header}</ModalHeader>
              <ModalBody>
                <EditFields />
              </ModalBody>
              <ModalFooter>
                <PrimaryButton
                  type="submit"
                  isDisabled={mutation.isPending}
                  width="100%"
                  ml="2"
                >
                  {mutation.isPending ? <Spinner /> : "Save"}
                </PrimaryButton>
                <SecondaryButton width="100%" ml="2" onClick={handleClose}>
                  Cancel
                </SecondaryButton>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
