import { FC, PropsWithChildren, TextareaHTMLAttributes, useState } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Textarea,
  TextareaProps,
  Text,
} from "@chakra-ui/react";
import { useField } from "formik";
import { defaultTo } from "ramda";

const defaultToString = defaultTo("");

export type TextareaFieldProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
  label?: string;
  isRequired: boolean;
  chakraProps?: TextareaProps;
  isDisabled?: boolean;
  maxLength?: number;
};

export const TextareaField: FC<TextareaFieldProps> = ({
  label,
  isRequired = false,
  isDisabled = false,
  chakraProps = {},
  maxLength = 250, // Default to 250 characters
  ...props
}: PropsWithChildren<TextareaFieldProps>) => {
  const [field, { touched, error }] = useField(props);
  const [charCount, setCharCount] = useState(field.value?.length || 0);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setCharCount(value.length);
    field.onChange(e);
  };

  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {label ? <FormLabel htmlFor={field.name}>{label}</FormLabel> : <></>}
      <InputGroup>
        <Textarea
          disabled={isDisabled}
          {...field}
          {...props}
          {...chakraProps}
          size={"md"}
          id={field.name}
          maxLength={maxLength}
          value={field.value}
          onChange={handleInputChange}
        />
      </InputGroup>
      <Text fontSize="sm" color="gray.500" mt={1}>
        {charCount}/{maxLength} characters
      </Text>
      {touched && error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};
