import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner } from "@chakra-ui/react";
import { PrimaryButton, SecondaryButton } from "components/molecules";
import { Form, Formik } from "formik";
import { User } from "types";
import { EditCountry, EditGender, EditLanguage, EditNameAge } from "../../common";
import { useEditRenter } from "lib";

type EditModalProps = {
  isOpen: boolean
  handleClose: () => void
  id: string
  user: User
}

const editTypes: {[key: string]: any} = {
  'language': {
    comp: EditLanguage,
    header: `Edit Languages`,
    fieldsToSubmit: (values: any) => ({
      language: values.language
    })
  },
  'countries': {
    comp: EditCountry,
    header: `Edit Country`,
    fieldsToSubmit: (values: any) => ({
      location: values.location
    })
  },
  'gender': {
    comp: EditGender,
    header: `Edit Gender`,
    fieldsToSubmit: (values: any) => ({
      gender: values.gender
    })
  },
  'name-age': {
    comp: EditNameAge,
    header: `Edit Name & Age`,
    fieldsToSubmit: (values: any) => ({
      name: values.name, age: values.age
    })
  },
}

export const EditModal = ({isOpen, handleClose, id, user} : EditModalProps) => {
  const {comp: EditFields, header, fieldsToSubmit} = editTypes[id]

  const mutation = useEditRenter()
  console.log(user)
  return(
    <Modal isOpen={isOpen} isCentered onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <Formik
            initialValues={user}
            validate={() => ({})}
            onSubmit={async (values) => {
              try{
                console.log('...')
                await mutation.mutateAsync(
                  fieldsToSubmit(values),
                  {onSuccess: () => {
                    handleClose()
                  },
                  onError: (e: any) => {
                    console.log(e)
                  }}  
                );
              }
              catch(e){console.log(e)}
            }}
          >
          <Form>
            <ModalHeader>
              {header}
            </ModalHeader>
            <ModalBody>
                <EditFields/>
            </ModalBody>
            <ModalFooter>
              <PrimaryButton type='submit' isDisabled={mutation.isPending} width="100%" ml="2">
                {mutation.isPending ? <Spinner /> : 'Save'}
              </PrimaryButton>
              <SecondaryButton width="100%" ml="2" onClick={handleClose}>Cancel</SecondaryButton>
            </ModalFooter>
          </Form>
        </Formik>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
