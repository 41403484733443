import { useGetTenants } from "lib";
import { useContext, useEffect, useRef } from "react";
import { Button, Flex, Input, Text } from "@chakra-ui/react";
import { Spinner } from "components/atoms";
import { TenantPost } from "./TenantPost";
import { ReviewButtons } from "../ReviewButtons";
import { useSwipeTenant } from "lib";
import { DashboardListing } from "context";
import { useSearchParams } from "react-router-dom";
import { useNotifications } from "components/organisms/Notifications";

export const TenantPosts = () => {

  const { listingId: page, setListingId: setPage } = useContext(DashboardListing);

  const [search, setSearchParams] = useSearchParams();
  
  let { isFetching, isError, data: tenantData } = useGetTenants(search.get('tenant') ? `query-${search.get('tenant')}` : page);
  const {
    shortlistMutation,
    acceptMutation
  } = useSwipeTenant();
  
  const moveItem = (animation: "animate__zoomOutUp"|"animate__fadeOutRight"|"animate__fadeOutLeft") => {
    if (postRef.current){
      setTimeout(() => next(),200);
      postRef.current.classList.remove('animate__animated', 'animate__zoomIn');
      postRef.current.classList.add('animate__animated', animation);  
    }
  }

  const shortlistTenant = (tenantId: string) => {
    shortlistMutation.mutate(tenantId, {
      onSuccess: () => 
        moveItem("animate__zoomOutUp")
    });
  }
  const acceptTenant = (tenantId: string) => {
    acceptMutation.mutate(tenantId, {
      onSuccess: () =>  
        moveItem("animate__fadeOutRight")
    });
  }
  const rejectTenant = (tenantId: string) => {
    // rejectMutation.mutate(tenantId, {
    //   onSuccess: () => 
        moveItem("animate__fadeOutLeft")
    // });
  }

  const nextItem = (dir: "accept"|"reject"|"shortlist"|"share") => {
    if (dir === "share"){
      const shareUrl = `${window.location.origin}/${tenantData ? `?tenant=${tenantData._id}` : ''}`
    
      if (navigator.share) {
        // Use Web Share API for supported devices
        navigator
          .share({
            title: "Check out this property!",
            url: shareUrl,
          })
          .then(() => console.log("Thanks for sharing!"))
          .catch((error) => console.error("Error sharing", error));
      } else {
        // Fallback for unsupported devices
        navigator.clipboard.writeText(shareUrl).then(() => {
          useNotifications.getState().addNotification({
            type: "success",
            title: "Link copied successfully",
            message: `Share it in your circle to increase the reach of your posting`,
          });
        });
      }
    }
    else {
      if (tenantData){
        if (dir === "shortlist"){
          shortlistTenant(tenantData._id)
        }
        if (dir === "accept"){
          acceptTenant(tenantData._id)
        }
        if (dir === "reject"){
          rejectTenant(tenantData._id)
        }
      }
    }
  }

  const next = () => {
    if (tenantData){
      if (search.get('tenant')){
        search.delete('tenant')
        setSearchParams(search)
      }
      else{
        setPage(tenantData._id);
      }
    }
  }

  const postRef = useRef<HTMLDivElement|null>(null);
  
  useEffect(() => postRef?.current?.focus(), []);

  return(
    <>
      {/* <SubHeader status={StatusEnum.Renter} /> */}
      {isFetching ? 
      <Flex alignItems={"center"} justifyContent={'center'} height={'100%'} >
        <Spinner />
      </Flex>
      : <></>}
      {!isFetching && !isError ? (
        <>
            {tenantData ? <TenantPost key={tenantData._id} ref={postRef} tenant={tenantData} /> : (
                <Flex
                  direction="column"
                  height="100%"
                  width={["100vw", "75vw"]}
                  alignItems={"center"}
                  justifyContent={"center"}
                  margin={"auto"}
                  textAlign="center"
                >
                  <Text fontSize="3xl" fontWeight="bold">
                    More listings soon!
                  </Text>
                  <Text fontSize="lg" mt={2}>
                    Be the first one to get notified about newer listings.
                  </Text>
                  <Flex mt={4} direction="row" alignItems="center">
                    <Input
                      type="email"
                      placeholder="Enter your email"
                      size="lg"
                      width="300px"
                      mr={2}
                    />
                    <Button colorScheme="blue" size="lg">
                      Submit
                    </Button>
                  </Flex>
                </Flex>
            )}
          <ReviewButtons 
          onClick={(a) => {nextItem(a)}}
          />
        </>
      ) : (<></>)}

    </>
  );
}