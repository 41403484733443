import { SliderComp } from "components/molecules"
import { FormikValues, useFormikContext } from "formik";

export const EditBudget = () => {
  const { values }: FormikValues = useFormikContext();
  
  return (
    <SliderComp
      step={50}
      name="tenantData.budget"
      label="Max budget for the room?"
      postfix="€"
      min={0}
      max={5000}
      sliderValue={values.tenantData.budget}
    />
  )
}