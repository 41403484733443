import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PropertyType, RoommateDetailType } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useAddRoommate = (propertyId: string) => {
  const qC = useQueryClient();
  const mutation = useMutation({
    mutationKey: ["addRoommate"],
    mutationFn: (roommatedetail: RoommateDetailType) => {
      const fd = new FormData();

      if (roommatedetail.pfp) {
        fd.append("pfp", roommatedetail.pfp, roommatedetail.pfp.name);
      }

      delete roommatedetail["pfp"];

      for (const key of Object.keys(roommatedetail)) {
        const val = roommatedetail[key as keyof typeof roommatedetail];
        fd.append(key, val as Blob);
      }

      return api.post(`/property/roommate/${propertyId}`, { body: fd }, true);
    },
    onSuccess: (res: any) => {
      qC.setQueryData(["property", propertyId], (oldData: PropertyType) => {
        if (oldData) {
          return {
            ...oldData,
            roommateDetails: oldData.roommateDetails
              ? oldData.roommateDetails.concat([res.data])
              : [res.data],
          };
        }
        return oldData;
      });
      useNotifications.getState().addNotification({
        type: "success",
        title: "Success",
        message: `Successfully updated room photos`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: "error",
        title: "Failed to update room photos",
        message: error.message ? error.message : "Try again",
      });
    },
  });
  return mutation;
};
