import { Box, Flex, GridItem, Image, Text, theme } from "@chakra-ui/react";
import { Body1 } from "../Text";

type RoomDetailProps = {
  icon: any;
  name: string;
  value: any;
};

export const RoomDetail = ({ icon, name, value }: RoomDetailProps) => (
  <GridItem colSpan={1}>
    <Flex flexDir={"row"} alignItems={"center"}>
      <Box
        cursor={"pointer"}
        marginRight={2}
        borderRadius={"50%"}
        backgroundColor={"rgba(0,0,0,0.05)"}
        padding={"2"}
      >
        <Image src={icon} />
      </Box>
      <Flex flexDir={"column"}>
        <Body1 fontSize="14px">{name}</Body1>
        <Text fontSize="14px" color={theme.colors.black[200]}>
          {value}
        </Text>
      </Flex>
      
    </Flex>
  </GridItem>
);
