import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "lib";
import { useNotifications } from "components/organisms";
import { TenantType } from "types";

export const useRemoveHighlight = () => {
  const qC = useQueryClient()

  const mutation = useMutation({
    mutationKey: ['removeHighlight'],
    mutationFn: async (image: number) => {
      return api.patch('/tenant/highlights', {body: JSON.stringify({image})}, true, 'json');
    },
    onSuccess: (_, image) => {
      qC.setQueryData(
        ['authenticated-user'],
        (oldData: TenantType) => {
          console.log(oldData, image)
          if (oldData){
            return {
              ...oldData, 
              tenantData: {
                ...oldData.tenantData, 
                photoHighlights: oldData.tenantData?.photoHighlights ? 
                  oldData.tenantData?.photoHighlights.filter((_, i) => i !== image) :
                  []
              }
            }
          }
          return oldData
        }
      )
      useNotifications.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: `Successfully updated highlights`,
      });
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to update hgihlights',
        message: error.message ? error.message : 'Try again',
      });
    }
  })
  return mutation;
}