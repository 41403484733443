import { Box, Divider, Flex, Grid, GridItem, Image, Spacer, theme, VStack, Text } from "@chakra-ui/react";
import { Body0, Body1, Swiper, Title1, Title3, OptionGroup } from "components/molecules";
import React, { ForwardedRef, forwardRef } from "react";
import Location from 'assets/images/map-pin.svg';
import Language from 'assets/images/languages.svg';
import Profession from 'assets/images/briefcase-business.svg';
import Duration from 'assets/images/calendar-clock.svg';
import Gender from 'assets/images/GenderIcon.svg';
import GlobeIcon from 'assets/images/earth.svg';
import SmokeIcon from 'assets/images/cigarette.svg';
import Animals from 'assets/images/paw-print.svg';
import Cooking from 'assets/images/cooking-pot.svg';
import Users from 'assets/images/users.svg';
import CleanlinessIcon from 'assets/images/CleanlinessIcon.svg';
import { TenantType } from "types";
import HomeIcon from 'assets/images/house.svg';
import { dateFormatter, GenderEnum, getDurationOfStay, Smoke, Occupation, Cleanliness, Pets, Socialize, Cook } from "utils";
import { countries_map, language_mappings } from "utils/data";
import { useNotifications } from "components/organisms/Notifications";
import { ShareIcon } from "lucide-react";

type RoomDetailProps = {
  icon: any;
  name: string;
  value: any;
};

const RoomDetail = ({ icon, name, value }: RoomDetailProps) => (
  <GridItem colSpan={1}>
    <Flex flexDir={'row'} alignItems={'center'}>
      <Box cursor={'pointer'} marginRight={2} borderRadius={'50%'} backgroundColor={'rgba(0,0,0,0.05)'} padding={'2'}>
        <Image src={icon} />
      </Box>

      <Flex flexDir={"column"}>
        <Body1 fontSize="14px">{name}</Body1>
        <Text fontSize="14px" color={theme.colors.black[200]}>
          {value}
        </Text>
      </Flex>
    </Flex>
  </GridItem>
);

const interests = ["Non-smoker", "Likes to clean", "Coffee", "Touring", "Music", "Reading"];

export const TenantPost = forwardRef(
  (
    {
      tenant,
      styles = {},
    }: {
      tenant: TenantType;
      styles?: React.CSSProperties;
    },
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    let { name, tenantData, age, location: currLocation, language, gender, _id: tenantId } = tenant;
    let {
      photoHighlights: pictures,
      moveInDate,
      moveOutDate,
      occupation,
      city: preferredLocation,
      smoke,
      cleanliness,
      pets,
      socialize,
      cook,
      descriptions,
      bio
    } = tenantData;

    const copyItem = () => {
      const shareUrl = `${window.location.origin}/?tenant=${tenantId}`
      navigator.clipboard.writeText(shareUrl).then(() => {
        useNotifications.getState().addNotification({
          type: 'success',
          title: 'Link copied successfully',
          message: `Share it in your circle to increase the reach of your posting`
        });
      });
    }

    return (
      <Flex
        ref={ref}
        flexDir={["column", "row"]}
        alignItems={"center"}
        justifyContent={"center"}
        paddingTop={[5, 10]}
        overflow={["none", "hidden"]}
        style={styles}
        className="animate__animated animate__zoomIn"
      >
        {/* Tenant details in this flex */}
        <Flex
          width={["100vw", "75vw"]}
          flexDir={["column", "row"]}
          border={["0px solid rgba(216, 220, 224, 1)", "1px solid rgba(216, 220, 224, 1)"]}
          borderBottom={"none"}
          borderRadius={["0px", "16px"]}
          borderBottomLeftRadius={"0"} /* No radius on bottom left */
          borderBottomRightRadius={"0"}
          padding={["0px", "16px"]}
          maxH={"100%"}
          className="hide-scrollbar"
          overflow={["scroll", "none"]}
        >
          {/* Room photos carousel */}
          <Swiper
            border="none"
            width="100%"
            height={["auto", "72vh"]}
            photoUrls={pictures.map((r: string) => `${process.env.REACT_APP_BASE_URL}/${r}`)}
          />

          <VStack padding={6} width={"100%"} maxH={"100%"} className="apartment-details" overflowX={"hidden"} overflowY={["scroll"]}>
            <Flex justifyContent={"space-between"} width={"100%"} alignItems={"center"}>
              <Title3 fontSize="2xl">
                {name}
                <ShareIcon size={'18px'} cursor={'pointer'} onClick={copyItem} style={{marginLeft: '5px', display : 'inline', backgroundColor: '#F7F7F7'}} />
              </Title3>
              <Spacer mr={2} />
              <Box
                backgroundColor={"#F7F7F7"} // Similar to the rent box
                padding={"8px 16px"}
                borderRadius={"24px"}
                textAlign="center"
                ml={2} // Adds spacing between name and age
              >
                <Text textStyle="medium" fontSize="lg" lineHeight="1.2">
                  {age}
                </Text>
                <Text fontSize="sm" color="gray.500" mt="2px">
                  years old
                </Text>
              </Box>
            </Flex>
            <Flex flexDir={"column"}>
              <Body0 fontSize="sm">{bio ?? ''}</Body0>
            </Flex>
            <Divider orientation="horizontal" my={2} />

            <Grid
              width={"100%"}
              templateRows="repeat(1, 1fr)"
              templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
              gap={5}
            >
              <RoomDetail icon={HomeIcon} name="Move-in date" value={<>{dateFormatter(moveInDate)}</>} />
              <RoomDetail icon={Location} name="Preferred location" value={preferredLocation} />
              <RoomDetail icon={Duration} name="Duration of Stay" value={getDurationOfStay(moveInDate, moveOutDate)} />
              <RoomDetail
                icon={Language}
                name="Languages"
                value={language?.split(",").map((l) => language_mappings[l]).join(",")}
              />

              <RoomDetail icon={Profession} name="Current Status" value={Occupation[occupation]} />
              <RoomDetail icon={GlobeIcon} name="From" value={countries_map[currLocation]} />
              <RoomDetail icon={Gender} name="Gender" value={GenderEnum[gender]} />
            </Grid>
            <Divider orientation="horizontal" my={2} />

            <Grid
              width={"100%"}
              templateRows="repeat(1, 1fr)"
              templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
              gap={5}
              align-items= "center"
            >
              <GridItem colSpan={[1, 2]}>
                <Title1>Additional Information</Title1>
              </GridItem>
              <RoomDetail icon={SmokeIcon} name="Smoke" value={Smoke[smoke]} />
              <RoomDetail icon={CleanlinessIcon} name="Cleanliness" value={Cleanliness[cleanliness]} />
              <RoomDetail icon={Animals} name="Pets" value={Pets[pets]} />
              <RoomDetail icon={Users} name="Socializing" value={Socialize[socialize]} />
              <RoomDetail icon={Cooking} name="Cooking" value={Cook[cook]} />
            </Grid>
            <Divider orientation="horizontal" my={2} />

            <Grid
              width={"100%"}
              templateRows="repeat(1, 1fr)"
              templateColumns={[`repeat(1, 1fr)`, `repeat(2, 1fr)`]}
              gap={5}
            >
              <GridItem colSpan={[1, 2]}>
                <Title1>Interests</Title1>
              </GridItem>
            </Grid>
            <OptionGroup
              nonFunctional={true}
              selected={descriptions}
              options={descriptions}
              onChange={(v) => {}}
              columns={[3, 4]}
            />
           
          </VStack>
        </Flex>
      </Flex>
    );
  }
);

