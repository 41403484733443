import { Box, Flex, GridItem, Image, Text, theme } from "@chakra-ui/react"
import { Body1 } from "components/molecules"
import { EditIcon } from "lucide-react"

type DetailBarProps = {
  icon?: any, 
  name: string, 
  value : any,
  img?: any,
  openModal?: () => void
}

export const DetailBar = ({icon, img, name, value, openModal}: DetailBarProps) => (
  <GridItem colSpan={1}>
    <Flex flexDir={'row'} alignItems={'center'}>
      <Box cursor={'pointer'} marginRight={2} borderRadius={'50%'} backgroundColor={'rgba(0,0,0,0.05)'} padding={'2'}>
        {icon ? <Image src={icon} /> : <></>}
        {img ? img : <></>}
      </Box>
      <Flex flexDir={'column'}>
        <Body1 fontSize='14px'>
          {name}
          {(openModal) ? <Flex display={'inline-flex'} ml={2} onClick={openModal} mb={'auto'} mt={1} cursor={'pointer'} alignItems={'flex-start'}>
            <EditIcon size={'14px'} />
          </Flex> : <></>}
        </Body1>
        <Text fontSize="14px" color={theme.colors.black[200]}>{value}</Text>
      </Flex>
    </Flex>
  </GridItem>
)
