import { Text, Box, Tab, TabIndicator, TabList } from "@chakra-ui/react";
import { Body1 } from "components/molecules";
import {
  useGetMatchedListings,
  useGetMatchedTenants,
  useGetPendingListing,
  useGetPendingTenants,
  useGetShortlistListing,
  useGetShortlistTenants,
} from "lib";

export const ApplicationTabsTenant = () => {
  let { data: accepted } = useGetMatchedListings();
  let { data: shortlisted } = useGetShortlistListing();
  let { data: pending } = useGetPendingListing();

  return (
    <ApplicationTabsInner
      data="Listings"
      accepted={accepted ? accepted.length : 0}
      shortlisted={shortlisted ? shortlisted.length : 0}
      pending={pending ? pending.length : 0}
    />
  );
};

export const ApplicationTabsRenter = () => {
  let { data: accepted } = useGetMatchedTenants();
  let { data: shortlisted } = useGetShortlistTenants();
  let { data: pending } = useGetPendingTenants();

  return (
    <ApplicationTabsInner
      data="Tenants"
      accepted={accepted ? accepted.length : 0}
      shortlisted={shortlisted ? shortlisted.length : 0}
      pending={pending ? pending.length : 0}
    />
  );
};

export const ApplicationTabsInner = ({
  data,
  accepted,
  shortlisted,
  pending,
}: {
  data: string;
  accepted: number;
  shortlisted: number;
  pending: number;
}) => (
  <Box my={6}>
    {" "}
    {/* Adds margin above and below the TabList */}
    <TabList
      width={[
        "100%",
        "fit-content",
      ]} /* Full width on mobile, fit-content on larger screens */
      display="flex"
      justifyContent={[
        "space-around",
        "center",
      ]} /* Distributes tabs evenly on mobile */
      padding={[1, 2]} /* Smaller padding on mobile */
      borderRadius={"50px"}
      zIndex={1}
      bgColor={"rgba(247, 247, 247, 1)"}
      flexWrap="wrap" /* Ensures wrapping on very small screens */
    >
      <Tab zIndex={3} flex={["1", "initial"]} textAlign="center">
        {" "}
        {/* Tabs take equal space on mobile */}
        <Body1 fontSize={["sm", "md"]}>
          Accepted {data} {!!accepted && <Text as={"span"}>{accepted}</Text>}
        </Body1>
      </Tab>
      <Tab zIndex={3} flex={["1", "initial"]} textAlign="center">
        <Body1 fontSize={["sm", "md"]}>
          Pending {data} {!!pending && <Text as={"span"}>{pending}</Text>}
        </Body1>
      </Tab>
      <Tab zIndex={3} flex={["1", "initial"]} textAlign="center">
        <Body1 fontSize={["sm", "md"]}>
          Shortlisted {data}{" "}
          {!!shortlisted && (
            <Text textStyle={"light"} fontSize={"small"} as={"span"}>
              {shortlisted}
            </Text>
          )}
        </Body1>
      </Tab>
      <TabIndicator
        zIndex={2}
        boxShadow={"0px 6px 17px 0px rgba(0, 0, 0, 0.08)"}
        height={["55px", "40px"]} /* Adjust height for smaller screens */
        bg="white"
        borderRadius="50px"
      />
    </TabList>
  </Box>
);
