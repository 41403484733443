import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChatMessages } from "types";
import { api } from "lib";
import { useNotifications } from "components/organisms";

export const useSendMessage = () => {
  const qC = useQueryClient()

  const mutation = useMutation({
    mutationKey: ['sendMessage'],
    mutationFn: async (msgData: {msg: string, to: string}) => {
      const res = await api.post('/chat', {body: JSON.stringify(msgData)}, true, 'json');
      return res.data
    },
    onSuccess: (msg: ChatMessages) => {
      qC.setQueryData(
        ['messages', msg.to], 
        (oldData: ChatMessages[]) => 
          oldData ? oldData.concat(msg) : [msg]
      )
    },
    onError: (error) => {
      useNotifications.getState().addNotification({
        type: 'error',
        title: 'Failed to send message',
        message: error.message ? error.message : 'Try again',
      });
    }
  })
  return mutation;
}