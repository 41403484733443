import { Flex, VStack } from "@chakra-ui/react";
import { Body1, Body4 } from "components/molecules";
import { ListingType } from "types";
import { ListingOptions } from "./listingOptions";
import { dateFormatter, GenderEnum } from "utils";
import { useState } from "react";
import { EditModal } from "./EditModals";
import { EditIcon } from "lucide-react";

const Section = ({
  keyy,
  val,
  onClick,
}: {
  keyy: string;
  val: string;
  onClick: () => void;
}) => (
  <VStack alignItems={"flex-start"} gap={0}>
    <Body1 display="flex">
      <span style={{ marginRight: "5px" }}>{keyy}</span>
      <EditIcon
        size={"14px"}
        cursor={"pointer"}
        display={"inline"}
        onClick={onClick}
      />
    </Body1>
    <Body4>{val}</Body4>
  </VStack>
);

export const ListingRow = ({ listing }: { listing: ListingType }) => {
  const {
    rent,
    securityDeposit,
    gender,
    age,
    moveInDate,
    moveOutDate,
    contract,
  } = listing;

  const [editModal, setEditModal] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>("");

  const handleOpen = (id: string) => {
    setEditId(id);
    setEditModal(true);
  };

  const handleClose = () => {
    setEditModal(false);
  };

  return (
    <Flex
      flexDir={["column", "row"]}
      justifyContent={["flex-start", "space-between"]}
    >
      <Section
        onClick={() => handleOpen("rent")}
        keyy="Rent"
        val={`${rent} €`}
      />
      <Section
        onClick={() => handleOpen("securityDeposit")}
        keyy="Deposit"
        val={`${securityDeposit} €`}
      />
      <Section
        onClick={() => handleOpen("contract")}
        keyy="Contract"
        val={contract ? "Yes" : "No"}
      />
      <Section
        onClick={() => handleOpen("gender")}
        keyy="Gender"
        val={GenderEnum[gender]}
      />
      <Section
        onClick={() => handleOpen("age")}
        keyy="Age"
        val={`${age} Yrs Old`}
      />
      <Section
        onClick={() => handleOpen("moveinout")}
        keyy="Start"
        val={dateFormatter(moveInDate, "ASAP")}
      />
      <Section
        onClick={() => handleOpen("moveinout")}
        keyy="End"
        val={dateFormatter(moveOutDate, "N/A")}
      />
      {editModal && (
        <EditModal
          listing={listing}
          id={editId}
          isOpen={editModal}
          handleClose={handleClose}
        />
      )}
    </Flex>
  );
};
