import { Flex } from "@chakra-ui/react";
import { RadioField } from "components/molecules";
import { FormikValues, useFormikContext } from "formik";

export const EditContract = () => {
  const { values }: FormikValues = useFormikContext();

  return (
    <Flex>
      <RadioField
        isRequired={true}
        label="Is the apartment available with contract or without?"
        options={{ 0: "With Contract", 1: "Without Contract" }}
        value={values.contract}
        name="contract"
      />
    </Flex>
  );
};
