import { TextareaField } from "components/molecules"
import { FormikValues, useFormikContext } from "formik"

export const EditBio = () => {
  const { values }: FormikValues = useFormikContext()
  
  return(
    <TextareaField
      placeholder='Enter a short bio or anything about you..'  
      isRequired={false}
      value={values.tenantData.bio}
      name='tenantData.bio'
    />

  )
}