import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { PrimaryButton, SecondaryButton } from "components/molecules";
import { Form, Formik } from "formik";
import { EditRoommateDetailForm } from "./form";
import { useAddRoommate, useEditRoommate } from "lib";
import { PropertyType, RoommateDetailType } from "types";

type EditModalProps = {
  handleClose: () => void;
  property: PropertyType;
  roommateIndex: number | null;
};

export const EditRoommateDetailModal = ({
  handleClose,
  property,
  roommateIndex,
}: EditModalProps) => {
  const mutation = useAddRoommate(property._id);
  const mutationEdit = useEditRoommate(property._id);

  return (
    <Modal isOpen={true} isCentered onClose={handleClose}>
      <ModalOverlay>
        <ModalContent>
          <Formik
            initialValues={
              roommateIndex !== null
                ? property.roommateDetails[roommateIndex]
                : {
                    name: "",
                  }
            }
            validate={() => ({})}
            onSubmit={async (values: RoommateDetailType) => {
              try {
                if (roommateIndex !== null) {
                  await mutationEdit.mutateAsync(
                    { roommatedetail: values, index: roommateIndex },
                    {
                      onSuccess: () => {
                        handleClose();
                      },
                      onError: (e: any) => {},
                    }
                  );
                } else {
                  await mutation.mutateAsync(values, {
                    onSuccess: () => {
                      handleClose();
                    },
                    onError: (e: any) => {},
                  });
                }
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <Form>
              <ModalHeader>Roommate Detail</ModalHeader>
              <ModalBody>
                <EditRoommateDetailForm />
              </ModalBody>
              <ModalFooter>
                <PrimaryButton
                  type="submit"
                  isDisabled={mutation.isPending}
                  width="100%"
                  ml="2"
                >
                  Save
                  {/* {mutation.isPending ? <Spinner /> : "Save"} */}
                </PrimaryButton>
                <SecondaryButton width="100%" ml="2" onClick={handleClose}>
                  Cancel
                </SecondaryButton>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
