import { TextareaField } from "components/molecules"
import { FormikValues, useFormikContext } from "formik"

export const EditOtherDetails = () => {
  const { values }: FormikValues = useFormikContext()
  
  return(
    <TextareaField
      placeholder='Enter other details about your property'  
      isRequired={false}
      value={values.extraDescription}
      name='extraDescription'
    />
  )
}