import { useQuery } from "@tanstack/react-query"
import { api } from "lib/api-client"
import { PropertyType } from "types"

export const useGetProperty = (id: string) => {
  let query = useQuery<PropertyType>({
    queryKey: ['property', id],
    queryFn: async () => {
      let r = await api.get(`/property/get/${id}`, {}, true)
      return r.data;
    },
    refetchOnWindowFocus: true,
    // data will be refetched on window focus after 30 mins
    staleTime: 30 * 60 * 1000
  })
  return query;
}