import { Flex } from "@chakra-ui/react"
import { InputField } from "components/molecules"
import { FormikValues, useFormikContext } from "formik"

export const EditRoommates = () => {
  const { values }: FormikValues = useFormikContext()
  
  return(
    <Flex>
      <InputField
        name="roommates"
        value={values.roommates}
        isRequired={true}
        label="Roommates"
        type="number"
        placeholder="3"
      />
    </Flex>
  )
}